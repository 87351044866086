import React, { useState } from 'react'
import { IoIosArrowUp } from '@react-icons/all-files/io/IoIosArrowUp'
import { IoIosArrowDown } from '@react-icons/all-files/io/IoIosArrowDown'

interface ExpandBoxProps {
  title: string | React.ReactNode
  arrowLabel?: string
  customTitleStyle?: string
  customBoxStyle?: string
  customChildrenStyle?: string
  children: React.ReactNode
}

const ExpandBox = ({
  title,
  arrowLabel = '',
  customTitleStyle = '',
  customBoxStyle = '',
  customChildrenStyle = '',
  children,
}: ExpandBoxProps): JSX.Element => {
  const [expand, setExpand] = useState(false)
  return (
    <div className={`rounded-xl bg-white  ${customBoxStyle}`}>
      <div
        className={`inline-flex items-center w-full hover:cursor-pointer ${
          expand ? ' rounded-t-xl border-b' : 'rounded-xl'
        } ${customTitleStyle}`}
        onClick={() => setExpand(!expand)}
      >
        <span className="flex-grow font-semibold text-md text-left p-4">{title}</span>
        <button
          onClick={() => setExpand(!expand)}
          className="inline-flex items-center space-x-1 justify-end p-4"
        >
          <span>{arrowLabel}</span>
          <div>{expand ? <IoIosArrowUp /> : <IoIosArrowDown />}</div>
        </button>
      </div>
      {expand && <div className={`rounded-b-xl px-4 py-2 ${customChildrenStyle}`}>{children}</div>}
    </div>
  )
}

export default ExpandBox
