import React, { useEffect } from 'react'
import { CreateListing } from '../../../@types/manager'
import dynamic from 'next/dynamic'
import { TextFormLabel } from '@components/generic/Text'
import 'react-datepicker/dist/react-datepicker.css'
import CalendarIcon from '@public/icons/calendar-icon.svg'
import FormInput from '@components/generic/FormInput'
import { formatTenancyDates } from 'src/lib/utilities'
interface ListingFormProps {
  handleInputChange: any
  newListing: Partial<CreateListing>
  setIsListingValid: any
}

const DatePicker = dynamic(() => import('react-datepicker'))

const ListingForm = ({ handleInputChange, newListing, setIsListingValid }: ListingFormProps) => {
  useEffect(() => {
    const { rent, availableDate, deposit } = newListing
    if (rent && availableDate && deposit) {
      setIsListingValid(true)
    } else {
      setIsListingValid(false)
    }
  }, [newListing])

  return (
    <form className="overflow-y-auto">
      <div className="flex flex-col flex-wrap">
        <div className="flex items-center mb-4">
          <TextFormLabel className="w-fit">No-fee listing:</TextFormLabel>
          <input
            type="checkbox"
            checked={newListing?.isNoFee}
            onChange={(e) => handleInputChange('isNoFee', e.target.checked)}
            className="ml-4 mr-2 rounded-3xl"
          />
          <span>Yes</span>
        </div>
        <div className="flex flex-col mb-4">
          <TextFormLabel isRequired>Date available</TextFormLabel>
          <div className="flex flex-row w-full items-center">
            <DatePicker
              selected={new Date(newListing?.availableDate)}
              minDate={new Date()}
              onChange={(date) => {
                handleInputChange('availableDate', formatTenancyDates(new Date(date as Date)))
              }}
              className="mt-2 border-light-40 rounded-3xl py-1 px-3 w-full focus:outline-none"
            />
            <CalendarIcon className="text-lightred-100 relative right-6 top-1 mb-1" />
          </div>
        </div>
        <div className="flex flex-row flex-wrap items-start justify-between mr-4">
          <div className="flex flex-col w-1/2 pr-2">
            <FormInput
              label="Monthly rent"
              isRequired
              type="number"
              value={newListing?.rent}
              numberType="float"
              decimalPlaces={2}
              onChange={(e) => handleInputChange('rent', e.target.value)}
              placeholder="$ Total rent amount"
            />
          </div>
          <div className="flex flex-col w-1/2 pl-2">
            <FormInput
              label="Security deposit"
              isRequired
              type="number"
              value={newListing?.deposit}
              onChange={(e) => handleInputChange('deposit', e.target.value)}
              placeholder="$ Total safety deposit amount"
            />
          </div>
        </div>
      </div>
    </form>
  )
}

export default ListingForm
