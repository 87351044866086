import React from 'react'

interface MiniCardVisualProps {
  color: string
  icon: JSX.Element
  value: string | JSX.Element
  displayText: string
  customContainerStyle?: string
  outerContainerStyle?: string
}

const colorStyles = {
  purple: { iconBg: 'bg-blue-200', gradient: '#393375' },
  pink: { iconBg: 'bg-lightred-200', gradient: '#F093B2' },
}

const MiniCardVisual = ({
  color,
  icon,
  value,
  displayText,
  customContainerStyle = '',
  outerContainerStyle = '',
}: MiniCardVisualProps) => {
  return (
    <div className={`relative w-1/2 md:w-full ${outerContainerStyle}`}>
      <div
        className={`rounded-lg shadow-md p-2 m-2 pt-8 relative h-28 bg-white ${customContainerStyle}`}
      >
        <div
          className={`${colorStyles[color].iconBg} w-10 h-10 rounded-md overflow-hidden text-white flex items-center justify-center absolute -top-4`}
        >
          {icon}
        </div>
        <div className="z-10">
          <div className="text-dark-900 text-2xl">{value}</div>
          <div className="text-dark-500 text-sm">{displayText}</div>
        </div>
        <div className="h-full absolute right-0 w-1/3 overflow-hidden rounded-r-lg top-0 z-0">
          <div
            className="absolute bottom-4"
            style={{
              width: '145%',
              height: '145%',
              background: `linear-gradient( ${colorStyles[color].gradient}, ${colorStyles[color].gradient}, transparent)`,
              clipPath: 'polygon(100% 0, 0% 100%, 100% 100%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default MiniCardVisual
