import { useRouter } from 'next/router'
import openiglooLogo from '@public/logo.png'
import Image from 'next/image'
// import { useAppSelector } from '@redux/hooks'
// import { useDispatch } from 'react-redux'
// import { setIsLoggedIn } from '@redux/actions'
// import { segment } from 'src/lib/analytics'
// import { useAuth } from 'src/stores/authContext'
// import ProfileMenu from './ProfileMenu'

export default function Navbar() {
  // const dispatch = useDispatch()
  // const auth = getFirebaseAuth()

  // const { profile, isLoggedIn } = useAppSelector((state) => state.currentUser)
  // const { logout } = useAuth()
  const router = useRouter()

  // const isLoginPage = router.pathname.startsWith('/login')

  return (
    <>
      <nav className="w-full shadow-md mx-auto sticky top-0 bg-white z-40">
        <div className="container max-w-screen-lg mx-auto py-2">
          <div className="flex items-center justify-between mx-auto w-11/12 xl:w-full">
            <div
              className="w-16 md:w-24 cursor-pointer flex items-center"
              onClick={() => {
                router.push('/')
              }}
            >
              <Image src={openiglooLogo} alt="Home" layout={'intrinsic'} />
            </div>
            <div className="inline-flex items-center">
              <a
                className="text-blue-300 mx-8 w-full text-center border-b py-1 border-white hover:border-blue-300 hover:cursor-pointer"
                href="https://www.openigloo.com"
              >
                For Renters
              </a>
              {/* {!isLoginPage && (
                <div className="mr-2 flex sm:mr-0 z-50">
                  {showLoginButtons && (
                    <div className="flex w-max space-x-3">
                      {isLoggedIn && profile ? (
                        <div className="w-full px-4 relative">
                          <ProfileMenu />
                        </div>
                      ) : (
                        <button
                          className="text-lightred-300 w-full text-center mx-2 py-1 border-b border-white hover:border-lightred-300 focus:outline-none"
                          onClick={() => {
                            router.push('/login?next=home')
                          }}
                        >
                          Login
                        </button>
                      )}
                    </div>
                  )}
                </div>
              )} */}
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}
