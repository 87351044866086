import React from 'react'

const openDataDescription = {
  violations: ``,
  evictions: `Below is a summary of court cases associated with this address from NYC's Department of Housing Preservation and Development.`,
  litigations: ``,
  bedbugInfestations: ``,
}

const OpenDataDescription = ({ collection }) => {
  const descriptionStyle = 'text-justify text-sm text-mid-300'

  return (
    <div className={'mt-3'}>
      <div className={descriptionStyle}>{openDataDescription[collection]}</div>
    </div>
  )
}

export default OpenDataDescription
