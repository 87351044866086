import { useAppSelector } from '@redux/hooks'
import { useEffect, useState } from 'react'
import { Amenity } from 'src/@types/manager'
import { titleCase } from 'src/lib/utilities'
import Image from 'next/image'
import IconTint from 'react-icon-tint'

const EditAmenities = ({
  amenities,
  unit = true,
  onEdit,
}: {
  amenities: Amenity[]
  unit?: boolean
  onEdit: (amenity: Amenity) => void
}) => {
  const [relevantAmenities, setRelevantAmenities] = useState([])
  const [activeAmenities, setActiveAmenities] = useState([])

  const { cityAmenities } = useAppSelector((state) => state.currentBuilding)

  useEffect(() => {
    if (cityAmenities && cityAmenities.length > 0) {
      if (unit) {
        setRelevantAmenities(cityAmenities.filter((amenity) => !amenity.isForBuilding))
      } else {
        setRelevantAmenities(cityAmenities.filter((amenity) => amenity.isForBuilding))
      }
    }
  }, [cityAmenities])

  useEffect(() => {
    if (amenities) {
      setActiveAmenities(amenities.map((am) => am.id))
    }
  }, [amenities])

  return (
    <div className="flex flex-row flex-wrap">
      {cityAmenities && cityAmenities.length > 0 ? (
        relevantAmenities.map((amenity) => {
          const selected = activeAmenities.includes(amenity.id)
          return (
            <button
              key={amenity.id}
              onClick={() => onEdit(amenity)}
              className={`bg-white flex flex-col flex-wrap items-center justify-center py-4 border rounded-lg my-4 mr-4 focus-no-shadow w-1/3 md:w-1/5 ${
                selected ? 'border-dark-900' : 'border-light-40'
              }`}
            >
              <IconTint
                maxWidth={40}
                maxHeight={40}
                src={amenity.icon}
                color={selected ? '#061938' : '#97A0AF'}
              />

              <label
                htmlFor={amenity.id}
                className={`${selected ? 'text-dark-900' : 'text-mid-300'} mt-1`}
              >
                {titleCase(amenity.name)}
              </label>
            </button>
          )
        })
      ) : (
        <div>No Amenities Listed</div>
      )}
    </div>
  )
}

export default EditAmenities
