import { TextFormLabel } from '@components/generic/Text'
import PositiveNumberInput from '@components/generic/PositiveNumberInput'

const FormInput = ({ label = '', isRequired = false, type = 'string', onChange, ...rest }) => {
  return (
    <>
      {label ? <TextFormLabel isRequired={isRequired}>{label}</TextFormLabel> : null}
      {type === 'string' ? (
        <input
          className="mt-2 py-1 px-3 rounded-3xl border border-light-40 focus:outline-none"
          onChange={onChange}
          {...rest}
        />
      ) : (
        <PositiveNumberInput
          className="mt-2 py-1 px-3 rounded-3xl border border-light-40 focus:outline-none"
          onChange={onChange}
          {...rest}
        />
      )}
    </>
  )
}

export default FormInput
