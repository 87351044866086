import Modal from '@components/generic/Modal'
import { sendEmailVerification } from 'firebase/auth'
import { useState } from 'react'
import BeatLoader from 'react-spinners/BeatLoader'
import { getFirebaseAuth } from 'src/lib/firebase'

export default function SendEmailVerification() {
  const [isSendingEmail, setIsSendingEmail] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [showMessageModal, setShowMessageModal] = useState(false)
  const auth = getFirebaseAuth()
  return (
    <>
      <button
        onClick={async () => {
          setIsSendingEmail(true)
          const user = auth.currentUser
          try {
            await sendEmailVerification(user)
            setShowMessageModal(true)
          } catch (err) {
            setShowErrorModal(true)
          }
          setIsSendingEmail(false)
        }}
        className="bg-dark-800 p-1 px-2 rounded-full"
      >
        {isSendingEmail ? (
          <BeatLoader color="white" size={5} />
        ) : (
          <span className="inline-flex space-x-1">
            <span>Send </span>
            <span className="hidden sm:block">Verification</span>
            <span>Email</span>
          </span>
        )}
      </button>
      <div className="text-black">
        {showErrorModal && (
          <Modal title="Error" onClose={() => setShowErrorModal(false)}>
            <div className="p-4">Unable to send email. Please try again!</div>
            <div className="w-full">
              <button
                onClick={() => setShowErrorModal(false)}
                className="bg-lightred-300 text-white primary-button mx-auto"
              >
                Close
              </button>
            </div>
          </Modal>
        )}
        {showMessageModal && (
          <Modal title="Email Sent" onClose={() => setShowMessageModal(false)}>
            <div className="p-4">
              Check your email and follow the link to verify your email address.
            </div>
            <div className="w-full">
              <button
                onClick={() => setShowMessageModal(false)}
                className="bg-lightred-300 text-white primary-button mx-auto"
              >
                Close
              </button>
            </div>
          </Modal>
        )}
      </div>
    </>
  )
}
