const ListingStatusPill = ({ status }: { status?: 'active' }) => {
  const common = 'rounded-2xl px-4 py-0.5 border text-xs'
  if (status === 'active') {
    return (
      <span className={`${common} border-lightred-75 text-secondary bg-lightred-50`}>
        Active listing
      </span>
    )
  } else {
    return (
      <span className={`${common} ${common} border-light-40 text-dark-600 bg-light-10`}>
        No Listing
      </span>
    )
  }
}

export default ListingStatusPill
