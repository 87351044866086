import Image from 'next/image'
import React from 'react'
import Loader from 'react-spinners/BeatLoader'
import openiglooLogoDark from '@public/logo.png'

const LoadingScreen = () => {
  return (
    <div className="w-full h-screen flex flex-col justify-center items-center">
      <div className="w-40 mb-2">
        <Image src={openiglooLogoDark} alt="OpenIgloo" layout="responsive" />
      </div>
      <Loader />
    </div>
  )
}

export default LoadingScreen
