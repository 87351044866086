import React from 'react'
import { twMerge } from 'tailwind-merge'

const Button = ({
  className = '',
  labelClassName = '',
  type = 'secondary',
  withIcon = false,
  Icon = null,
  label,
  style = {},
  disabled = false,
  ...rest
}) => {
  let bgColor = 'bg-lightred-300'
  if (type === 'primary') bgColor = 'bg-lightred-500'
  return (
    <button
      className={twMerge(
        `${
          disabled ? 'bg-light-40' : bgColor
        } text-white text-sm rounded-3xl px-4 py-2 flex flex-row items-center justify-center outline-none hover:outline-none focus:outline-none ${className}`
      )}
      style={{ minWidth: 'max-content', ...style }}
      disabled={disabled}
      {...rest}
    >
      {withIcon ? Icon : null}
      <span className={twMerge(`${disabled ? 'text-mid-300' : 'text-light-0'} ${labelClassName}`)}>
        {label}
      </span>
    </button>
  )
}

export const ButtonOutline = ({ label, className = '', labelClassName = '', ...rest }) => {
  return (
    <Button
      label={label}
      className={twMerge(`${className} bg-light-0 border border-light-40`)}
      labelClassName={twMerge(`${labelClassName} text-dark-600`)}
      {...rest}
    />
  )
}

export default Button
