const InfoSectionLayout = ({
  heading,
  children,
  editable = false,
  customStyle = '',
  id = '',
}: {
  heading?: string | JSX.Element
  editable?: boolean
  children: React.ReactNode
  customStyle?: string
  id?: string
}) => {
  return (
    <div
      className={`border rounded-2xl my-4 p-7 ${editable ? '' : 'bg-gray-100'} ${customStyle}`}
      id={id}
    >
      {heading ? <h4 className="font-bold mb-4">{heading}</h4> : <></>}
      {children}
    </div>
  )
}

export default InfoSectionLayout
