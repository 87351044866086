import { Button, ButtonOutline, ListingStatusPill } from '@components/index'
import CreateListingButton from '@components/listing/components/CreateListingButton'
import React, { useEffect, useRef, useState } from 'react'
import Table from '@components/table'
import { ColDef } from 'ag-grid-community'
import { currency, formatDate, titleCase } from 'src/lib/utilities'
import { useAuth } from '@stores/authContext'
import router from 'next/router'
import { isMobile as rddIsMobile } from 'react-device-detect'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import {
  setPaginatedManagedUnitsForCurrentBuilding,
  resetPaginatedManagedUnits,
  cleanupPaginatedManagedUnits,
} from '@redux/actions'
import { BeatLoader } from 'react-spinners'
import PaginationView from '@components/common/PaginationView'
import FilterIcon from '@public/icons/filter-icon.svg'
import Tippy from '@tippyjs/react'
import ActiveListingIcon from '@public/icons/active-listing-icon.svg'
import NoListingIcon from '@public/icons/no-listing-icon.svg'
import WithTenantsIcon from '@public/icons/with-tenants-icon.svg'
import WithNoTenantsIcon from '@public/icons/with-no-tenants-icon.svg'
import SearchLensIcon from '@public/icons/search-lens-icon.svg'
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose'
import usePagination from 'src/hooks/usePagination'

const ManagedUnitsList = ({ buildingId }: { buildingId: string }) => {
  const { token } = useAppSelector((state) => state.currentUser)
  const { paginatedManagedUnits } = useAppSelector((state) => state.currentBuilding)
  const { userPermissions } = useAuth()
  const [rowData, setRowData] = useState([])
  const [openMenu, setOpenMenu] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const tippyRef = useRef()
  const show = () => setOpenMenu(true)
  const hide = () => setOpenMenu(false)
  const dispatch = useThunkDispatch()
  const pageSizes = [10, 20, 30, 40]

  useEffect(() => {
    setIsMobile(rddIsMobile)
  }, [rddIsMobile])

  const { currentPage, setCurrentPage, pageSize, setPageSize, currentParams, setCurrentParams } =
    usePagination({
      data: paginatedManagedUnits,
      pageSizes,
      setData: (props) =>
        dispatch(
          setPaginatedManagedUnitsForCurrentBuilding({
            token,
            buildingId,
            ...props,
          })
        ),
      resetData: () => dispatch(resetPaginatedManagedUnits()),
      cleanupData: (pagesToCleanup) => dispatch(cleanupPaginatedManagedUnits(pagesToCleanup)),
    })

  const lastPage = Math.ceil(paginatedManagedUnits.count / pageSize)

  const [columnDefs] = useState<ColDef[]>([
    {
      field: '',
      headerName: 'Unit',
      cellRenderer: ({ data }) => {
        return (
          <>
            <span className="font-semibold">
              {data.unitNumber}
              <span className="font-normal text-sm text-dark-700">
                {' '}
                ({data.noBedrooms} bed, {data.noBathrooms} ba)
              </span>
            </span>
          </>
        )
      },
    },
    {
      headerName: 'Listing status',
      field: '',
      sortable: false,
      maxWidth: 200,
      cellRenderer: ({ data }) => {
        return (
          <>
            {data.activeListing ? <ListingStatusPill status={'active'} /> : <ListingStatusPill />}
          </>
        )
      },
    },
    {
      headerName: 'Rent',
      field: '',
      sortable: false,
      maxWidth: 150,
      cellRenderer: ({ data }) => {
        return <>{data.rent ? currency(data.rent) : <span className="text-mid-400">N/A</span>}</>
      },
    },
    {
      headerName: 'Tenants',
      field: '',
      sortable: false,
      maxWidth: 200,
      autoHeight: true,
      cellRenderer: ({ data }) => {
        return (
          <>
            {data.profiles.length > 0 ? (
              <span className="break-normal whitespace-normal">
                {data.profiles.map((profile) => titleCase(profile.name)).join(', ')}
              </span>
            ) : (
              <span className="text-mid-400">Empty unit</span>
            )}
          </>
        )
      },
    },
    {
      headerName: 'lease duration',
      field: '',
      sortable: false,
      minWidth: 300,
      autoHeight: true,
      cellRenderer: ({ data }) => {
        return (
          <div className="flex flex-row flex-wrap items-start justify-between">
            <div className="w-1/2 pr-4">
              {data.duration ? (
                <span className="whitespace-normal">{data.duration}</span>
              ) : (
                <span className="text-mid-400">N/A</span>
              )}
            </div>
            {userPermissions && userPermissions.role === 'building_manager' ? (
              <div className="w-1/2 mt-2 mb-4">
                <Button
                  label="Manage unit"
                  style={{ width: '120px' }}
                  labelClassName="text-xs"
                  onClick={() =>
                    router.push(
                      `/buildings/${data.productUnit.buildingId}/unit/${data.productUnit.id}`
                    )
                  }
                />
                {data.profiles.length > 0 ? (
                  <ButtonOutline
                    label="Manage tenants"
                    className="w-24 mt-2"
                    labelClassName="text-xs"
                    onClick={() =>
                      router.push(
                        `/buildings/${data.productUnit.buildingId}/unit/${data.productUnit.id}/tenant`
                      )
                    }
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        )
      },
    },
  ])

  const [mobileColumnDefs] = useState<ColDef[]>([
    {
      autoHeight: true,
      cellRenderer: ({ data }) => {
        return (
          <div className="flex flex-col pb-4 pt-2 pl-4">
            <span className="font-semibold">
              {data.unitNumber}
              <span className="font-normal text-sm text-dark-700">
                {' '}
                ({data.noBedrooms} bed, {data.noBathrooms} ba)
              </span>
            </span>
            <div className="-mt-3">
              {data.activeListing ? <ListingStatusPill status={'active'} /> : <ListingStatusPill />}
            </div>
          </div>
        )
      },
    },
    {
      autoHeight: true,
      cellRenderer: ({ data }) => {
        return (
          <div className="flex flex-col py-4 pl-2">
            <span className="text-sm font-semibold whitespace-normal">
              {data.profiles.length} tenants
            </span>
            <span className="text-xs font-semibold whitespace-normal">
              Rent: {data.rent ? currency(data.rent) : 'N/A'}
            </span>
            <span className="text-xs text-dark-700 whitespace-normal">
              Lease: {data.duration ? data.duration : 'pending'}
            </span>
          </div>
        )
      },
    },
  ])

  useEffect(() => {
    if (paginatedManagedUnits.items[currentPage]?.length > 0) {
      setRowData(
        paginatedManagedUnits.items[currentPage].map((unit) => {
          let leaseData = { rent: 0, profiles: [] }
          let duration = ''
          if (unit.leases?.length > 0) {
            leaseData = unit.leases?.reduce(
              (prevV, currV) => {
                return {
                  rent: prevV.rent + currV.rent,
                  profiles: [...prevV.profiles, ...currV.profiles],
                }
              },
              { rent: 0, profiles: [] }
            )
            duration = `${formatDate(unit.leases[0].start, 'MMM DD, YYYY')} to ${formatDate(
              unit.leases[0].end,
              'MMM DD, YYYY'
            )} `
          }
          return {
            ...unit.cityUnit,
            ...leaseData,
            duration,
          }
        })
      )
    }
  }, [paginatedManagedUnits, currentPage])

  const setParams = (value) => {
    hide()
    setCurrentParams(value)
  }

  const dropDownContent = (
    <div className="w-56 bg-white border rounded-lg shadow-lg flex flex-col text-base overflow-hidden py-1">
      <div
        className={`flex flex-row items-center py-2 px-4 cursor-pointer ${
          currentParams.isListed ? 'bg-lightred-50' : 'bg-light-0'
        }`}
        onClick={() => setParams({ isListed: true })}
        key="activelistings"
      >
        <ActiveListingIcon className="mr-2" /> Active listings
      </div>
      <div
        className={`flex flex-row items-center py-2 px-4 cursor-pointer ${
          currentParams.isListed === false ? 'bg-lightred-50' : 'bg-light-0'
        }`}
        onClick={() => setParams({ isListed: false })}
        key="nolisting"
      >
        <NoListingIcon className="mr-2" /> No listing
      </div>
      <div
        className={`flex flex-row items-center py-2 px-4 cursor-pointer ${
          currentParams.isLeased ? 'bg-lightred-50' : 'bg-light-0'
        }`}
        onClick={() => setParams({ isLeased: true })}
        key="withtenants"
      >
        <WithTenantsIcon className="mr-2" /> Units with tenants
      </div>
      <div
        className={`flex flex-row items-center py-2 px-4 cursor-pointer ${
          currentParams.isLeased === false ? 'bg-lightred-50' : 'bg-light-0'
        }`}
        onClick={() => setParams({ isLeased: false })}
        key="withnotenants"
      >
        <WithNoTenantsIcon className="mr-2" /> Units with no tenants
      </div>
      <div
        className={`flex flex-row items-center py-2 px-4 cursor-pointer text-secondary text-sm`}
        onClick={() => setParams({})}
        key="resetfilters"
      >
        Reset filters
      </div>
    </div>
  )

  return (
    <>
      <div className="md:flex md:flex-row md:items-end md:justify-between mb-6 md:-mx-6 md:px-16">
        <div className="flex flex-row items-center flex-wrap md:flex-nowrap">
          <div className="flex w-full md:w-max justify-between md:justify-start">
            <span className="text-dark-600 text-sm mr-2 mt-4 md:mt-0">Items per page:</span>
            {pageSizes.map((item) => (
              <button
                key={`items_per_page_${item}`}
                className={`mt-4 md:mt-0 px-2 py-1 rounded mr-2 cursor-pointer text-xs ${
                  pageSize === item ? 'bg-light-0 border border-light-40' : 'text-mid-80'
                }`}
                onClick={() => setPageSize(item)}
              >
                {item}
              </button>
            ))}
          </div>
          <PaginationView
            itemsPerPage={pageSize}
            setItemsPerPage={setPageSize}
            totalNumber={paginatedManagedUnits.count}
            pageNumber={currentPage}
            onNext={() => setCurrentPage(currentPage + 1)}
            onNextEnd={() => setCurrentPage(lastPage)}
            onBack={() => setCurrentPage(currentPage - 1)}
            onBackEnd={() => setCurrentPage(1)}
          />
        </div>
        <div className="flex flex-row items-center mt-4 md:mt-0">
          <div
            className={`rounded-3xl border border-light-40 bg-light-10 flex-1 h-10 flex flex-row items-center justify-center py-2 px-3`}
          >
            <SearchLensIcon className="text-primary mr-2 mb-0.5" />
            <input
              className="bg-transparent inline-block focus:outline-none w-max"
              onChange={(e) =>
                setCurrentParams({ ...currentParams, address: e.target.value ?? '' })
              }
              placeholder="Search for a unit"
              value={currentParams.address ?? ''}
            />
            <div className="ml-auto flex items-center justify-items-center w-4">
              {currentParams.address ? (
                <button
                  onClick={() => {
                    const params = currentParams
                    delete params['address']
                    setCurrentParams({ ...params })
                  }}
                >
                  <GrFormClose size={20} />
                </button>
              ) : null}
            </div>
          </div>
          <Tippy
            ref={tippyRef}
            content={dropDownContent}
            visible={openMenu}
            onClickOutside={hide}
            appendTo={document.body}
            interactive={true}
            placement="bottom"
          >
            <button
              className={`ml-4 rounded-full items-center justify-center py-2 px-4 font-normal border bg-light-10 border-light-40 text-sm focus:outline-none ${
                openMenu ? 'border-dark-900' : ''
              }`}
              onClick={openMenu ? hide : show}
            >
              <span
                className={`flex flex-row items-center text-sm ${
                  openMenu ? 'text-dark-900' : 'text-dark-600'
                }`}
                data-action="toggle"
              >
                <FilterIcon className={`${openMenu ? 'text-dark-900' : 'text-mid-80'} md:mr-2`} />{' '}
                <span className="hidden lg:block">Filters</span>
              </span>
            </button>
          </Tippy>
        </div>
      </div>
      {!paginatedManagedUnits.items[currentPage] ? (
        <div className="flex items-center justify-center w-full">
          <BeatLoader size={9} color="#E23668" />
        </div>
      ) : (
        <div className={`${isMobile ? 'overflow-x-hidden' : 'overflow-x-auto -mx-7 px-16'} my-6`}>
          {paginatedManagedUnits.count > 0 ? (
            <Table
              rowData={rowData}
              columnDefs={isMobile ? mobileColumnDefs : columnDefs}
              hideHeader={isMobile}
              immutableData
              getRowNodeId={(data) => data.unitNumber}
            />
          ) : (
            <div className="flex flex-col items-center justify-center">
              <span className="my-2">You have no managed listings.</span>
              <CreateListingButton />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default ManagedUnitsList
