import React, { useState } from 'react'
import UnitsIcon from '@public/icons/units-icon.svg'
import CalendarIcon from '@public/icons/calendar-icon.svg'
import FloorsIcon from '@public/icons/floors-icon.svg'
import {
  saveCurrentBuildingDescription,
  saveCurrentBuildingImages,
  updateCurrentBuilding,
} from '@redux/actions'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { useEffect } from 'react'
import { UploadImages, Skeleton, Pill, LoadingScreen, FormSection, Button } from '@components/.'
import ShowUploadedImages from '@components/common/ShowUploadedImages'
import OpenDataModal from '@components/building/OpenDataModal'
import { useAuth } from '@stores/authContext'
import { segment } from 'src/lib/analytics'
import dynamic from 'next/dynamic'

const HTMLEditor = dynamic(() => import('@components/generic/HTMLEditor'), {
  ssr: false,
})

const BuildingDetails = () => {
  const { token } = useAppSelector((state) => state.currentUser)
  const { currentBuilding } = useAppSelector((state) => state.currentBuilding)
  const { userPermissions } = useAuth()

  const dispatch = useThunkDispatch()

  const [isDescSaving, setIsDescSaving] = useState(false)
  const [description, setDescription] = useState('')
  const [selectedOpenDataType, setSelectedOpenDataType] = useState(null)

  useEffect(() => {
    if (currentBuilding) {
      setDescription(currentBuilding.description ?? '')
    }
  }, [currentBuilding])

  const saveDescription = async () => {
    setIsDescSaving(true)
    if (currentBuilding && token) {
      await dispatch(saveCurrentBuildingDescription(currentBuilding.id, description ?? '', token))
    }
    setIsDescSaving(false)
  }

  if (!currentBuilding) {
    return <LoadingScreen />
  }

  return (
    <>
      <div className="md:mx-12 md:px-12 pt-4">
        <span className="text-2xl text-primary font-semibold block">Overview</span>
        <div className="flex flex-col mt-4 md:flex-row">
          {currentBuilding.cityBuilding.noUnits ? (
            <div className="mr-10 mb-2 flex items-center">
              <UnitsIcon className="mr-3 text-mid-300" />
              <span className="text-dark-700">
                {currentBuilding.cityBuilding.noUnits || <Skeleton style="w-8" />} units
              </span>
            </div>
          ) : null}
          {currentBuilding.cityBuilding.builtYear ? (
            <div className="mr-10 mb-2 flex items-center">
              <CalendarIcon className="mr-3 text-mid-300" />
              <span className="text-dark-700">
                Built in {currentBuilding.cityBuilding.builtYear || <Skeleton style="w-8" />}
              </span>
            </div>
          ) : null}
          {currentBuilding.cityBuilding.noFloors ? (
            <div className="mr-10 mb-2 flex items-center">
              <FloorsIcon className="mr-3 text-mid-300" />
              <span className="text-dark-700">
                {currentBuilding.cityBuilding.noFloors || <Skeleton style="w-8" />} Floors
              </span>
            </div>
          ) : null}
        </div>
        <div className="flex-wrap inline-flex justify-start w-full mt-2 mb-8">
          {currentBuilding.cityBuilding.tags &&
            currentBuilding.cityBuilding.tags.map((tag, index: number) => (
              <div
                key={`pill-${index}`}
                className={`${tag.category == 'warning' ? 'cursor-pointer' : ''}`}
                onClick={() => {
                  tag.category == 'warning' && setSelectedOpenDataType(tag.collection)
                }}
              >
                <Pill
                  label={tag.label}
                  color={tag.category == 'warning' ? 'yellow' : 'purple'}
                  pillStyle="my-2 mr-3 p-1 truncate text-xxs w-max lg:px-5"
                />
              </div>
            ))}
        </div>
        <FormSection
          title="Building images"
          canEdit={userPermissions && userPermissions.role === 'building_manager'}
        >
          <>
            {userPermissions && userPermissions.role === 'building_manager' && (
              <UploadImages
                storageUrl={`buildings/${currentBuilding.cityBuilding.productBuilding.id}`}
                onUploadComplete={(uploadedUrls) => {
                  if (!currentBuilding || !token) return
                  const savedImages = [
                    ...(currentBuilding.cityBuilding.savedImages || []),
                    ...uploadedUrls,
                  ]
                  segment.trackBuildingImagesUpdate(currentBuilding.cityBuilding.fullAddress)
                  dispatch(
                    updateCurrentBuilding({
                      ...currentBuilding,
                      cityBuilding: {
                        ...currentBuilding.cityBuilding,
                        savedImages,
                      },
                    })
                  )
                  dispatch(saveCurrentBuildingImages(currentBuilding.id, savedImages, token))
                }}
              />
            )}

            {currentBuilding && currentBuilding.cityBuilding.savedImages ? (
              currentBuilding.cityBuilding.savedImages.length > 0 ? (
                <ShowUploadedImages
                  isEditable={userPermissions && userPermissions.role == 'building_manager'}
                  images={currentBuilding.cityBuilding.savedImages}
                  onEdit={(photos) => {
                    if (!currentBuilding || !token) return
                    let savedImages = photos.length > 0 ? photos : null
                    segment.trackBuildingImagesUpdate(currentBuilding.cityBuilding.fullAddress)
                    dispatch(
                      updateCurrentBuilding({
                        ...currentBuilding,
                        cityBuilding: {
                          ...currentBuilding.cityBuilding,
                          savedImages,
                        },
                      })
                    )
                    dispatch(saveCurrentBuildingImages(currentBuilding.id, savedImages, token))
                  }}
                />
              ) : (
                <div>No images uploaded yet</div>
              )
            ) : (
              <div>No images uploaded yet</div>
            )}
          </>
        </FormSection>
        <FormSection
          title="Description"
          canEdit={userPermissions && userPermissions.role === 'building_manager'}
        >
          {userPermissions && userPermissions.role === 'building_manager' ? (
            <HTMLEditor value={description || ''} onChange={(value) => setDescription(value)} />
          ) : description ? (
            <div
              // editor-lists class unreset the css for lists
              className="editor-lists"
              dangerouslySetInnerHTML={{
                __html: `<pre>${description}</pre>`,
              }}
            ></div>
          ) : (
            <span>No description added yet</span>
          )}
          {userPermissions && userPermissions.role === 'building_manager' && (
            <Button
              label="Save description"
              onClick={saveDescription}
              disabled={isDescSaving}
              className="mx-auto w-full md:w-1/2 justify-center mt-4"
            />
          )}
        </FormSection>
      </div>
      {selectedOpenDataType && (
        <OpenDataModal
          onClose={() => {
            setSelectedOpenDataType(null)
          }}
          collection={selectedOpenDataType}
        />
      )}
    </>
  )
}

export default BuildingDetails
