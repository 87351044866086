interface PositiveNumberInputProps {
  onChange: (e: any) => void
  numberType?: 'int' | 'float'
  decimalPlaces?: number
  [x: string]: any
}

const PositiveNumberInput = ({
  onChange,
  decimalPlaces,
  numberType = 'int',
  ...props
}: PositiveNumberInputProps) => {
  if (props.value === undefined || props.value === null) {
    props.value = ''
  }

  const checkDecimalPlaces = (value: string) => {
    if (decimalPlaces === undefined || !value.includes('.')) {
      return true
    }
    return value.toString().split('.')[1].length <= decimalPlaces
  }

  const change = (e) => {
    const decimal = decimalPlaces === 0 || numberType === 'int' ? /[\d]+$/ : /^\d*\.?\d*$/
    const value = e.target.value
    const matches = decimal.test(value) && (numberType === 'int' ? true : checkDecimalPlaces(value))
    if (!value || matches) {
      onChange(e)
    }
  }

  return <input type="text" inputMode="numeric" onChange={change} {...props} />
}

export default PositiveNumberInput
