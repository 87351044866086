import { useAppSelector } from '@redux/hooks'
import { createLoadingSelectors } from '@redux/actions'

export default (actionsToLoad: string[]) => {
  const loadingState = useAppSelector((state) => state.currentLoading)
  const { fetchingSelector, errorSelector } = createLoadingSelectors(actionsToLoad)
  const isFetching = fetchingSelector(loadingState)
  const isError = errorSelector(loadingState)

  return {
    isFetching,
    isError,
  }
}
