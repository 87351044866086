import { MdCancel } from '@react-icons/all-files/md/MdCancel'
import React from 'react'
import { Building } from '@openigloo/types'
import BuildingCard from '../cards/BuildingCard'
import HorizontalScrollSection from '../generic/HorizontalScrollSection'
import { IoCloseSharp } from 'react-icons/io5'
import OpeniglooBuildingCard from '@components/cards/OpeniglooBuildingCard'

type BuildingListProps = {
  buildings: Building[]
  onRemove?: (building: Building, index: number) => void
  onClick?: (building: Building) => void
}

const BuildingList = ({
  buildings,
  onRemove = () => {},
  onClick = () => {},
}: BuildingListProps): JSX.Element => {
  return (
    <>
      {buildings?.length > 0 &&
        buildings.map((bld, index) => (
          <div key={bld.id} className="mr-8 mb-8 flex-shrink-0 relative">
            <button
              onClick={() => {
                onRemove(bld, index)
              }}
              title="Remove"
              className="absolute -right-2 -top-2 z-10 bg-lightred-300 rounded-full p-0.5"
            >
              <IoCloseSharp color="#fff" size={20} />
            </button>

            <OpeniglooBuildingCard
              building={bld}
              customCardStyle="w-80 cursor-pointer"
              onCardClick={() => {
                onClick(bld)
              }}
            />
          </div>
        ))}
    </>
  )
}

export default BuildingList
