import { MdAddLocationAlt } from 'react-icons/md'
import { useRouter } from 'next/router'
import { useAuth } from 'src/stores/authContext'
import { segment } from 'src/lib/analytics'
import { useAppSelector } from '@redux/hooks'

const ClaimBuildingButton = ({ showBuildingModal }) => {
  const { userPermissions } = useAuth()
  const router = useRouter()

  return (
    <button
      className="border border-light-40 bg-white primary-button text-lightred-500 sm:px-3.5 sm:py-2 text-sm sm:w-48 w-max"
      onClick={() => showBuildingModal()}
    >
      <MdAddLocationAlt size={20} className="mr-2" />
      <span>Add Building</span>
    </button>
  )
}

export default ClaimBuildingButton
