import { MouseEventHandler } from 'react'

export default function ActionButton({
  label,
  onClick,
  customStyle = '',
  style = 'primary',
  size = 'normal',
  disabled = false,
}: {
  label: string
  onClick: MouseEventHandler
  customStyle?: string
  style?: string
  size?: string
  disabled?: boolean
}): JSX.Element {
  let bgColor = 'bg-lightred-300'
  if (style === 'secondary') {
    bgColor = 'bg-gray-500'
  }

  let minWidth = 'min-w-56'
  if (size === 'large') {
    minWidth = 'min-w-72'
  }
  if (size === 'small') {
    minWidth = 'min-w-40'
  }

  return (
    <button onClick={onClick} disabled={disabled} className={`focus:outline-none ${customStyle} text-white ${bgColor} rounded-3xl ${minWidth} text-center m-1 mx-auto`}>
      {label}
    </button>
  )
}
