import React, { useState, useEffect } from 'react'

import { FiChevronsLeft, FiChevronLeft, FiChevronsRight, FiChevronRight } from 'react-icons/fi'

interface PaginationViewProps {
  itemsPerPage: number
  setItemsPerPage: any
  totalNumber: number
  pageNumber: number
  onNext: any
  onNextEnd: any
  onBack: any
  onBackEnd: any
  loading?: boolean
}

const PaginationView = ({
  itemsPerPage,
  setItemsPerPage,
  totalNumber,
  pageNumber,
  onNext,
  onNextEnd,
  onBack,
  onBackEnd,
  loading = false,
}: PaginationViewProps) => {
  const [startResult, setStartResult] = useState(1)
  const [endResult, setEndResult] = useState(itemsPerPage)

  useEffect(() => {
    if (pageNumber && itemsPerPage) {
      const calcStart = (pageNumber - 1) * itemsPerPage + 1
      setStartResult(totalNumber > 0 ? calcStart : 0)
      const calcEnd = pageNumber * itemsPerPage
      setEndResult(calcEnd > totalNumber ? totalNumber : calcEnd)
    }
  }, [pageNumber, itemsPerPage, totalNumber])

  return (
    <div className="flex flex-wrap items-center h-full text-dark-600 text-xs sm:text-sm space-x-4">
      {/* <div className="space-x-2 flex items-center w-full sm:w-max justify-center sm:justify-start">
        <div>Items per page:</div>
        <div
          className={`h-8 w-8 flex items-center justify-center ${
            itemsPerPage == 10
              ? 'border-light-40 border bg-white rounded-lg text-dark-900'
              : 'text-mid-80 hover:cursor-pointer'
          }`}
          onClick={() => setItemsPerPage(10)}
        >
          10
        </div>
        <div
          className={`h-8 w-8 flex items-center justify-center ${
            itemsPerPage == 20
              ? 'border-light-40 border bg-white rounded-lg text-dark-900'
              : 'text-mid-80 hover:cursor-pointer'
          }`}
          onClick={() => setItemsPerPage(20)}
        >
          20
        </div>
        <div
          className={`h-8 w-8 flex items-center justify-center ${
            itemsPerPage == 30
              ? 'border-light-40 border bg-white rounded-lg text-dark-900'
              : 'text-mid-80 hover:cursor-pointer'
          }`}
          onClick={() => setItemsPerPage(30)}
        >
          30
        </div>
        <div
          className={`h-8 w-8 flex items-center justify-center ${
            itemsPerPage == 40
              ? 'border-light-40 border bg-white rounded-lg text-dark-900'
              : 'text-mid-80 hover:cursor-pointer'
          }`}
          onClick={() => setItemsPerPage(40)}
        >
          40
        </div>
      </div> */}

      <div className="flex items-center text-mid-80 w-full sm:w-max justify-center sm:justify-start mt-4 sm:mt-0">
        <button
          onClick={onBackEnd}
          disabled={pageNumber == 1 || loading}
          className={pageNumber == 1 || loading ? 'hover:cursor-default text-light-40' : ''}
        >
          <FiChevronsLeft size={16} />
        </button>
        <button
          onClick={onBack}
          disabled={pageNumber == 1 || loading}
          className={pageNumber == 1 || loading ? 'hover:cursor-default text-light-40' : ''}
        >
          <FiChevronLeft size={16} />
        </button>
        <div className="mx-2 text-dark-600 text-xs">
          {startResult} - {endResult} of {totalNumber}
        </div>
        <button
          onClick={onNext}
          disabled={endResult == totalNumber || loading}
          className={
            endResult == totalNumber || loading ? 'hover:cursor-default text-light-40' : ''
          }
        >
          <FiChevronRight size={16} />
        </button>
        <button
          onClick={onNextEnd}
          disabled={endResult == totalNumber || loading}
          className={
            endResult == totalNumber || loading ? 'hover:cursor-default text-light-40' : ''
          }
        >
          <FiChevronsRight size={16} />
        </button>
      </div>
    </div>
  )
}

export default PaginationView
