import { titleCase } from 'src/lib/utilities'

import { GiKnifeFork } from '@react-icons/all-files/gi/GiKnifeFork'
import { GiWoodenFence } from '@react-icons/all-files/gi/GiWoodenFence'
import { GiElevator } from '@react-icons/all-files/gi/GiElevator'
import { GiSecurityGate } from '@react-icons/all-files/gi/GiSecurityGate'
import { GiParkBench } from '@react-icons/all-files/gi/GiParkBench'

import { CgSmartHomeWashMachine } from '@react-icons/all-files/cg/CgSmartHomeWashMachine'
import { MdLocalLaundryService } from '@react-icons/all-files/md/MdLocalLaundryService'
import { FaCarAlt } from '@react-icons/all-files/fa/FaCarAlt'
import { FaSwimmingPool } from '@react-icons/all-files/fa/FaSwimmingPool'
import { CgGym } from '@react-icons/all-files/cg/CgGym'
import { useEffect, useState } from 'react'
import { useAppSelector } from '@redux/hooks'
import Image from 'next/image'
import { Amenity } from 'src/@types/manager'

const amenityIcons = {
  doorman: <GiSecurityGate size={20} />,
  elevator: <GiElevator size={20} />,
  gym: <CgGym size={20} />,
  'laundry room': <MdLocalLaundryService size={20} />,
  'outdoor space': <GiParkBench size={20} />,
  parking: <FaCarAlt size={20} />,
  pool: <FaSwimmingPool size={20} />,
  dishwasher: <GiKnifeFork size={20} />,
  'private outdoor space': <GiWoodenFence size={20} />,
  'washer dryer': <CgSmartHomeWashMachine size={20} />,
}

const AmenityIcon = ({ icon, name, active = true }) => {
  return (
    <div className="inline-flex w-48 items-center mb-3">
      <div className={`mx-auto p-2 rounded-md mr-2 ${active ? 'bg-gray-300' : 'bg-gray-100'}`}>
        <div className={`w-8 h-8 relative`}>
          <Image src={icon} alt={name} layout="fill" className={`${active ? '' : 'opacity-20'}`} />
        </div>
      </div>
      <span className={`flex-1 ${active ? '' : 'opacity-30'}`}>{titleCase(name)}</span>
    </div>
  )
}

const DisplayAmenities = ({ amenities, unit = true }: { amenities: Amenity[]; unit?: boolean }) => {
  const [relevantAmenities, setRelevantAmenities] = useState([])
  const [activeAmenities, setActiveAmenities] = useState([])

  const { cityAmenities } = useAppSelector((state) => state.currentBuilding)

  useEffect(() => {
    if (cityAmenities && cityAmenities.length > 0) {
      if (unit) {
        setRelevantAmenities(cityAmenities.filter((amenity) => !amenity.isForBuilding))
      } else {
        setRelevantAmenities(cityAmenities.filter((amenity) => amenity.isForBuilding))
      }
    }
  }, [cityAmenities])

  useEffect(() => {
    if (amenities) {
      setActiveAmenities(amenities.map((am) => am.id))
    }
  }, [amenities])

  return (
    <div className="flex flex-row flex-wrap">
      {cityAmenities && cityAmenities.length > 0 ? (
        relevantAmenities.map((amenity) => (
          <div key={amenity.id}>
            <AmenityIcon
              icon={amenity.icon}
              name={amenity.name}
              active={activeAmenities.includes(amenity.id)}
            />
          </div>
        ))
      ) : (
        <div>No Amenities Listed</div>
      )}
    </div>
  )
}

export default DisplayAmenities
