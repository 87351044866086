import SearchLensIcon from '@public/icons/search-lens-icon.svg'
import Script from 'next/script'
import { useEffect, useRef, useState } from 'react'
import { searchAddress } from 'src/data/Api'
import usePlacesAutocomplete, { RequestOptions } from 'use-places-autocomplete'
import { Suggestion } from '@openigloo/types'
import BeatLoader  from 'react-spinners/BeatLoader'
import { GrFormClose } from '@react-icons/all-files/gr/GrFormClose'
import useOnClickOutside from 'use-onclickoutside'
import { titleCase } from 'src/lib/utilities'
import { segment } from 'src/lib/analytics'
import { useAppSelector } from '@redux/hooks'

type SearchBarProps = {
  placeholder?: string
  onSuggestionSelected: (item: Suggestion, index: number) => void
  inputAutofocus?: boolean
}

export const NYC_LAT = 40.7128
export const NYC_LNG = -74.006

const SearchBar = ({
  onSuggestionSelected,
  inputAutofocus = false,
  placeholder = 'Search for an address',
}: SearchBarProps) => {
  const [requestOptions, setRequestOptions] = useState<RequestOptions>({
    componentRestrictions: { country: 'us' },
  })
  const [suggestions, setSuggestions] = useState<(Suggestion | null)[]>([])
  const [isSearching, setIsSearching] = useState(false)
  const [selectedOption, setSelectedOption] = useState(-1)

  const [showSuggestions, setShowSuggestions] = useState(false)

  const ref = useRef(null)
  useOnClickOutside(ref, () => setShowSuggestions(false))

  const { currentBuilding } = useAppSelector((state) => state.currentBuilding)

  const {
    ready,
    value,
    suggestions: { status, data, loading },
    setValue,
    clearSuggestions,
    init,
  } = usePlacesAutocomplete({
    requestOptions: requestOptions,
    debounce: 400,
  })

  useEffect(() => {
    const getSuggestions = async () => {
      setIsSearching(true)
      const backendSearchResults = data.map((suggestion) => {
        return searchAddress(suggestion.description, suggestion.place_id).catch((err) => {
          return null
        })
      })

      const buildings = (await Promise.all(backendSearchResults)).filter((bld) => bld !== null)
      setSuggestions([...buildings])
      setIsSearching(false)
    }

    if (data) {
      getSuggestions()
      setSelectedOption(-1)
    } else {
      setSuggestions([])
    }
  }, [data])

  useEffect(() => {
    if (ready) {
      setRequestOptions({
        location: new google.maps.LatLng(NYC_LAT, NYC_LNG),
        radius: 50 * 1000,
        types: ['address'],
        sessionToken: new google.maps.places.AutocompleteSessionToken(),
        componentRestrictions: { country: 'us' },
      })
    }
  }, [ready])

  useEffect(() => {
    if (currentBuilding) {
      setValue(currentBuilding.cityBuilding.fullAddress)
    }
  }, [currentBuilding])

  const handleKeyDown = (e) => {
    if (!suggestions?.length) {
      return
    }
    if (e.keyCode === 40) {
      e.preventDefault()
      setSelectedOption((selectedOption + 1) % suggestions.length)
    } else if (e.keyCode === 38) {
      e.preventDefault()
      if (selectedOption > 0) {
        setSelectedOption(selectedOption - 1)
      } else {
        setSelectedOption(suggestions.length - 1)
      }
    } else if (e.keyCode === 13) {
      onSuggestionSelected(suggestions[selectedOption], selectedOption)
      clearSuggestions()
      setSuggestions([])
    }
  }

  return (
    <div
      ref={ref}
      className={`border bg-white w-full h-10 relative z-50 shadow-sm ${
        suggestions.length > 0 && showSuggestions
          ? 'rounded-t-2xl border-none shadow-md'
          : 'rounded-3xl'
      }`}
    >
      <Script
        src="https://maps.googleapis.com/maps/api/js?key=AIzaSyA2nGmwjwgnxfDyJe4aRzHu_ZXgxNBRcfI&libraries=places"
        onLoad={() => {
          init()
        }}
      />
      <div
        className={`rounded w-full h-10 flex flex-row items-center justify-center py-2 px-3`}
      >
        <SearchLensIcon className='text-primary mr-2' />
        <input
          className="bg-transparent inline-block focus:outline-none w-11/12 px-1"
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            setValue(e.target.value || '')
            setShowSuggestions(true)
          }}
          onFocus={(e) => {
            setValue(e.target.value)
            segment.trackSearchAddressTap()
          }}
          placeholder={placeholder}
          value={value}
          autoFocus={inputAutofocus}
        />
        <div className="ml-auto flex items-center justify-items-center w-8">
          {isSearching ? (
            <BeatLoader size={3} color="#000000" />
          ) : (
            value && (
              <button
                aria-label="clear selection"
                onClick={() => {
                  clearSuggestions()
                  setSuggestions([])
                  setValue('')
                  setShowSuggestions(false)
                }}
              >
                <GrFormClose size={20} />
              </button>
            )
          )}
        </div>
      </div>
      {!isSearching && showSuggestions ? (
        <ul className="relative -top-1 w-full rounded-b-xl overflow-hidden z-40 bg-white  border-t-0  shadow-md">
          {suggestions.map((item, index) => (
            <li
              className={`relative block h-auto px-5 py-2 cursor-pointer text-left hover:bg-bluegray-300 hover:text-white ${
                selectedOption === index ? 'bg-bluegray-300 text-white' : ''
              }`}
              key={`bld-${index}`}
              onClick={() => {
                if (item) {
                  onSuggestionSelected(item, index)
                  clearSuggestions()
                  setSuggestions([])
                  setShowSuggestions(false)
                }
              }}
            >
              {titleCase(item?.address)}
            </li>
          ))}
        </ul>
      ) : (
        ''
      )}
    </div>
  )
}

export default SearchBar
