// check the source code from https://github.com/JackPu/reactjs-percentage-circle
import c from 'classnames'
import React from 'react'

interface Props {
  innerText?: string
  percent?: number
  color?: string
  bgcolor?: string
  innerColor?: string
  radius?: number
  borderWidth?: number
  text?: string
  textClass?: string
  icon?: JSX.Element
  showPercentInside?: boolean
}

const CirclePercentage = (props: Props): JSX.Element => {
  const {
    innerText = '',
    color = '#424067',
    percent = 50,
    radius = 50,
    bgcolor = '#EAEAFF',
    innerColor = '#FFFFFF',
    borderWidth = 6,
    text = '',
    textClass = 'text-regular',
    icon = false,
    showPercentInside = true,
  } = props
  let right, left
  if (percent >= 50) {
    right = '180deg'
    left = (percent - 50) * 3.6 + 'deg'
  } else {
    right = percent * 3.6 + 'deg'
    left = '0deg'
  }

  const leftTransformerDegree = left
  const rightTransformerDegree = right

  return (
    <div
      className="relative overflow-hidden"
      style={{
        width: radius * 2,
        height: radius * 2,
        borderRadius: radius,
        backgroundColor: bgcolor,
      }}
    >
      <div
        className="absolute top-0 overflow-hidden"
        style={{
          width: radius,
          height: radius * 2,
          left: 0,
        }}
      >
        <div
          className="absolute top-0 right-0 origin-fir"
          id="id1"
          style={{
            left: radius,
            width: radius,
            height: radius * 2,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            backgroundColor: color,
            transform: 'rotate(' + leftTransformerDegree + ')',
          }}
        />
      </div>
      <div
        className="absolute top-0 left-0 overflow-hidden  "
        style={{
          width: radius,
          height: radius * 2,
          left: radius,
        }}
      >
        <div
          className="absolute top-0 left-0 origin-sec overflow-hidden"
          id="id2"
          style={{
            left: -radius,
            width: radius,
            height: radius * 2,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor: color,
            transform: 'rotate(' + rightTransformerDegree + ')',
          }}
        />
      </div>
      <h3
        className="relative flex flex-col items-center justify-center text-center"
        style={{
          left: borderWidth,
          top: borderWidth,
          width: (radius - borderWidth) * 2,
          height: (radius - borderWidth) * 2,
          borderRadius: radius - borderWidth,
          backgroundColor: innerColor,
        }}
      >
        {showPercentInside && (
          <div className="w-full text-center text-3xl font-bold">
            {innerText ? innerText : `${percent}%`}
          </div>
        )}
        <div className={c('w-3/5 text-center text-xs font-normal', textClass)}>{text}</div>
        {icon && <div>{icon}</div>}
      </h3>
    </div>
  )
}

export default CirclePercentage
