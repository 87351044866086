import { ReviewCard, StarRating, Tooltip } from '@components/index'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { BuildingOverview, OwnerComment } from '@openigloo/types'
import { useAppSelector } from '@redux/hooks'
import { Buildings } from 'src/data'
import { useAuth } from '@stores/authContext'
import { ManagedCityBuilding, Review } from 'src/@types/manager'
import { useEffect, useState } from 'react'
import MiniCardVisual from '@components/home/MiniCardVisual'
import { RiHomeHeartLine } from 'react-icons/ri'
import { BsFillPersonCheckFill } from 'react-icons/bs'
import { AiOutlineInfoCircle } from 'react-icons/ai'

const EditReview = ({
  currentBuilding,
  reviews,
  onChange,
  overview,
}: {
  currentBuilding: ManagedCityBuilding
  reviews: Review[]
  onChange: () => void
  overview: BuildingOverview
}) => {
  const { token } = useAppSelector((state) => state.currentUser)
  const [percentageSummary, setPercentageSummary] = useState({
    recommendBuilding: 0,
    approveOwner: 0,
  })

  const { userPermissions } = useAuth()

  const onCommentDelete = async (comment: OwnerComment) => {
    confirmAlert({
      title: 'Delete comment',
      message: 'Are you sure you want to delete your comment?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            await Buildings.deleteComment(comment.id, token)
            onChange()
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    })
  }

  const onCommentEdit = async (review: Review, comment: string) => {
    if (!review.ownerComment) {
      await Buildings.createComment(review.id, comment, token)
    } else {
      await Buildings.updateComment(review.ownerComment.id, comment, token)
    }
    onChange()
  }

  useEffect(() => {
    const getPercentageSummary = async () => {
      const responses = await Buildings.getBuildingResponses(currentBuilding.id, token)
      setPercentageSummary({
        recommendBuilding: (responses.numRecommendBuilding / responses.numReviews) * 100 || 0,
        approveOwner: (responses.numApproveOwner / responses.numReviews) * 100 || 0,
      })
    }
    getPercentageSummary()
  }, [currentBuilding])

  const averageRating = currentBuilding.averageRating
    ? parseFloat(currentBuilding.averageRating.toFixed(2))
    : currentBuilding.averageRating

  const RatingsInfo = () => {
    return (
      <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
        {overview?.aggRatings?.map((data, index) => {
          return (
            <div key={index}>
              <div className="text-base font-semibold text-dark-900">{data.name}</div>
              <div className="text-mid-500 text-sm">
                1 ({data.minDescription}) to 5 ({data.maxDescription})
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="md:mx-12 md:px-12 pt-4">
      <div className="flex flex-row items-center">
        <span className="pb-1 mr-2">Average rating: </span>
        <StarRating rating={averageRating} starSize={14} />
        <span className="mx-2">{averageRating ? averageRating.toFixed(2) : averageRating}</span>
        <span className="text-mid-400">({reviews?.length || 0}) </span>
      </div>
      {overview &&
        <div>
          <div className='flex items-center'>
            <div className="my-8 text-2xl text-primary font-semibold block pr-4">Building ratings</div>
            <Tooltip description={<RatingsInfo />} customStyle="sm:w-96 !p-4 sm:left-0 -left-20 w-60">
              <AiOutlineInfoCircle />
            </Tooltip>
          </div>
          <div
            className={`border-graylight-500 mt-2 rounded-xl border py-5`}
          >
            {overview?.aggRatings &&
              overview?.aggRatings.map((rating) => (
                <div
                  className="mx-auto px-8 py-1 font-semibold sm:inline-flex sm:w-1/2 sm:justify-between"
                  key={rating.id}
                >
                  <div className="mr-2 text-base text-dark-900">{rating.name}</div>
                  <StarRating rating={rating.value} />
                  <h3 className="hidden">{`${rating.name}: ${rating.value}`}</h3>
                </div>
              ))}
          </div>
        </div>
      }
      <div className="my-8 text-2xl text-primary font-semibold block">Renter recommendations</div>
      <div className="flex flex-row mt-4">
        <MiniCardVisual
          color={'purple'}
          icon={<RiHomeHeartLine size={25} />}
          value={percentageSummary.recommendBuilding.toString().split('.')[0] + '%'}
          displayText={'recommend your buildings'}
          customContainerStyle="mb-4"
        />

        <MiniCardVisual
          color={'purple'}
          icon={<BsFillPersonCheckFill size={20} />}
          value={percentageSummary.approveOwner.toString().split('.')[0] + '%'}
          displayText={'approve of property owner'}
          customContainerStyle="mb-4"
        />
      </div>
      <div className="my-8 text-2xl text-primary font-semibold block">Recent reviews</div>
      {reviews?.length > 0 ? (
        reviews.map((review) => (
          <div key={review.id} className="mb-4">
            <ReviewCard
              canComment={userPermissions.isRespondingToComments}
              review={review}
              onCommentEdit={onCommentEdit}
              onCommentDelete={onCommentDelete}
              showImageSection
              showExpanded
              showBuildingAddress
            />
          </div>
        ))
      ) : (
        <div className="flex flex-col items-center mt-6 justify-center">
          <span className="my-2">Your building has no reviews yet.</span>
        </div>
      )}
    </div>
  )
}

export default EditReview
