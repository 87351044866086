import Link from 'next/link'
import Image from 'next/image'
import getConfig from 'next/config'

import { FaFacebook } from '@react-icons/all-files/fa/FaFacebook'
import { AiOutlineInstagram } from '@react-icons/all-files/ai/AiOutlineInstagram'
import { FaLinkedinIn } from '@react-icons/all-files/fa/FaLinkedinIn'
import { AiOutlineTwitter } from '@react-icons/all-files/ai/AiOutlineTwitter'

import openiglooLogo from '../../../public/logo.png'
const { publicRuntimeConfig } = getConfig()

export default function Footer(): JSX.Element {
  return (
    <footer className="sm:mx-0 w-full pb-6 border-gray-200 border-t pt-10">
      <div className="text-center max-w-screen-lg mx-auto mb-3 text-dark-800 text-md font-normal tracking-wider border-b-2 border-light-50 pb-4">
        <div className="flex justify-between w-11/12 mx-auto ">
          <div className="w-1/2 md:w-1/3 flex-col justify-center mb-3">
            <div className="w-full mb-2 relative">
              <div className="w-full h-16 flex-auto lg:h-24 mx-auto">
                <Link href="/" passHref>
                  <a>
                    <Image src={openiglooLogo} alt="Home" layout="fill" objectFit="contain" />
                  </a>
                </Link>
              </div>
            </div>
            <div className="mx-auto flex justify-evenly items-center sm:mt-0 text-bluegray-500 w-5/6">
              <div className="w-6 h-6 overflow-hidden rounded-sm">
                <a
                  className="hover:opacity-80"
                  href="https://www.instagram.com/openigloo"
                  target="_blank"
                  rel="noreferrer"
                >
                  <AiOutlineInstagram size="1.5em" className="relative" />
                </a>
              </div>
              <div className="w-6 h-6 overflow-hidden rounded-sm">
                <a
                  className="hover:opacity-80"
                  href="https://www.facebook.com/openigloo"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebook size="1.1em" className="relative mx-auto top-1" />
                </a>
              </div>
              <div className="w-6 h-6 overflow-hidden rounded-sm">
                <a
                  className="hover:opacity-80"
                  href="https://www.linkedin.com/company/openigloo/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedinIn size="1.2em" className="relative mx-auto top-1" />
                </a>
              </div>
              <div className="w-6 h-6 overflow-hidden rounded-sm">
                <a
                  className="hover:opacity-80"
                  href="https://twitter.com/openigloo"
                  target="_blank"
                  rel="noreferrer"
                >
                  <AiOutlineTwitter size="1.2em" className="relative top-1" />
                </a>
              </div>
            </div>
          </div>
          <nav className="w-full flex justify-evenly">
            <ul className="flex flex-col sm:flex-row sm:flex-wrap sm:items-start w-full my-auto mx-2 pl-5 justify-center sm:justify-evenly sm:space-x-2">
              <li className="hover:text-bluegray-50">
                <Link href="/listings-quality-policy">
                  <a>Listings Quality Policy</a>
                </Link>
              </li>
              <li className="hover:text-bluegray-50">
                <Link href="/faqs">
                  <a>FAQs</a>
                </Link>
              </li>
              <li className="hover:text-bluegray-50">
                <Link href="https://www.openigloo.com/community-guidelines">
                  <a>Community Guidelines</a>
                </Link>
              </li>
              {/* <li className="hover:text-bluegray-50">
                <a href="mailto:info@openigloo.com">Contact Us</a>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
      
      <div className="pt-4 px-4 flex flex-wrap flex-col sm:flex-row w-full justify-between max-w-screen-lg mb-3 mx-auto text-mid-100 font-medium text-base text-center ">
        <span className="sm:text-right">
          © COPYRIGHT {new Date().getFullYear()}, openigloo Inc. All Rights Reserved.
        </span>
        <div className="text-dark-600 font-semibold">
          <Link href="https://www.openigloo.com/privacy-policy">
            Privacy Policy
          </Link>
        </div>
        <div className="text-dark-600 font-semibold">
          <Link href="https://www.openigloo.com/terms-of-use">
            Terms of Use
          </Link>
        </div>
        <span className="text-mid-100 pl-3">{`Version ${publicRuntimeConfig.APP_VERSION}`}</span>
      </div>
    </footer>
  )
}
