import {initializeApp, getApps, FirebaseOptions} from 'firebase/app';
import { FirebaseStorage, getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

export const getFirebaseApp = (config: FirebaseOptions|undefined) => {
  if (!getApps().length) {
    if (config) {
      return initializeApp(config)
    } else {
      throw new Error('No Config Provided');
    }
  }
  return getApps()[0]
}

export function getFirebaseStorage(config: FirebaseOptions|undefined) {
  return getStorage(getFirebaseApp(config));
}

export const uploadToFirebaseStorage =  async (
  storage: FirebaseStorage,
  storagePath: string,
  fileToUpload: Blob,
  onComplete: ((url: string) => void) | null,
  onProgress?: (progress: number) => void
  ): Promise<any> => {
  const extension = fileToUpload.type.replace(/(.*)\//g, '');

  const documentRef = ref(storage, `${storagePath}/${uuidv4()}.${extension}`);
  
  const uploadTask = uploadBytesResumable(documentRef, fileToUpload);

  uploadTask.on('state_changed', (snapshot) => {
    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    if (onProgress) {
      onProgress(progress);
    }
  })

  return await uploadTask.then(() => {
    return getDownloadURL(uploadTask.snapshot.ref).then((url : string) => {
      if (onComplete) {
        onComplete(url);
      }
      return url
    })
  })
}