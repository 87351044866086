import { FirebaseOptions } from 'firebase/app';
import { useCallback, useEffect, useRef, useState } from 'react';
import { getFirebaseStorage, uploadToFirebaseStorage } from './lib/firebase';
import useLatest from './useLatest';


export interface FirebaseStorageHookArgs {
  storageUrl: string
  config?: FirebaseOptions;
}

interface FileUploadStatus {
  isUploading: boolean;
  progress: number;
  fileUrl: string | null;
}

export const DefaultCallback = (progress: number) => progress;

const useFirebaseStorage = ({
  storageUrl,
  config
}: FirebaseStorageHookArgs) => {
  const storage = getFirebaseStorage(config);

  const [isUploadingFinished, setIsUploadingFinished] = useState(false);
  const [files, setFiles] = useState<Blob[]>([]);
  const [filesUploadStatus, setFilesUploadStatus] = useState<FileUploadStatus[]>([]);
  const [uploadedUrls, setUploadedUrls] = useState<string[]>([]);
  const filesUploadStatusRef = useRef<FileUploadStatus[]>([]);

  const uploadFiles = async () => {    
    setIsUploadingFinished(false);
    const uploadTasks: Promise<string>[] = [];
    files.forEach((file: any, index: any) => {
      uploadTasks.push(
          uploadToFirebaseStorage(storage, storageUrl, file, (url) => {
            const fUS = [...filesUploadStatusRef.current];
            fUS[index] = {
              isUploading: false,
              progress: 100,
              fileUrl: url
            }
            setFilesUploadStatus(fUS);
            filesUploadStatusRef.current = fUS;
          },
          (progress) => {
            const fUS = [...filesUploadStatusRef.current];
            fUS[index] = {
              isUploading: progress !== 100,
              progress: progress,
              fileUrl: null
            }
            setFilesUploadStatus(fUS);
            filesUploadStatusRef.current = fUS;
          })
      )
    })

    const urls = await Promise.all(uploadTasks);
    setUploadedUrls(urls);
    setIsUploadingFinished(true);
  }

  useEffect(() => {
    if (files.length > 0 && filesUploadStatus.length > 0) {
      uploadFiles();
    }
  }, [files]);

  const setFilesToUpload = useCallback(
    (files: Array<Blob>) => {
      setFilesUploadStatus(Array(files.length).fill({
        isUploading: true,
        fileUrl: null,
        progress: 0,
      }))
      setFiles(() => files);
      setUploadedUrls([]);
    }, []
  )

  return {
    setFilesToUpload,
    filesUploadStatus,
    files,
    isUploadingFinished,
    uploadedUrls
  }

}

export default useFirebaseStorage;

