import React, { useEffect, useState } from 'react'
import {
  OccupiedUnitsCard,
  AvailableListingsCard,
  ApplicationsSummaryCard,
  AverageRatingCard,
  LeadsSummaryCard,
  OutstandingRentDueCard,
  RevenueCard,
  HorizontalScrollSection,
} from '@components/index'
import { useAppSelector } from '@redux/hooks'
import { Months } from 'src/lib/utilities'
import { UserProfile } from 'src/data'
import { useAuth } from '@stores/authContext'
import { RoleType } from 'src/@types/manager'
import dynamic from 'next/dynamic'
import BeatLoader from 'react-spinners/BeatLoader'
import useLoading from 'src/hooks/useLoading'
import ACTIONS from '@redux/constants'

const AppOptInModal = dynamic(() => import('@components/overview/AccessApplicationsModal'))

const cardsToScroll = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
    slidesToSlide: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
    slidesToSlide: 3,
  },
  bigMobile: {
    breakpoint: { max: 768, min: 640 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
}
const SummaryHeader = () => {
  const { profile, token, managementSummary } = useAppSelector(
    (state) => state.currentUser
  )

  const { userPermissions } = useAuth()
  const [showAppOptInModal, setShowAppOptInModal] = useState(false)
  const { isFetching } = useLoading([ACTIONS.USER.GET_MANAGEMENT_SUMMARY])

  const getSummaryCards = (role: RoleType) => {
    if (managementSummary) {
      const {
        outstandingRent,
        paidRent,
        ratingSummary,
        unitsSummary,
        applicationsSummary,
        listingsSummary,
      } = managementSummary

      switch (role) {
        case 'building_manager':
        case 'coop_condo_manager':
          return (
            <HorizontalScrollSection customResponsive={cardsToScroll}>
              {ratingSummary && (
                <div className="py-4 pr-8 md:pr-2 lg:pr-4">
                  <AverageRatingCard
                    value={ratingSummary.averageRating}
                    reviewCount={ratingSummary.reviewsCount}
                    hasAccess={true}
                    isLoading={isFetching}
                  />
                </div>
              )}

              {unitsSummary && (
                <div className="py-4 px-8 md:px-2 lg:px-4">
                  <OccupiedUnitsCard
                    value={unitsSummary.numOccupiedUnits}
                    hasAccess={true}
                    isLoading={isFetching}
                  />
                </div>
              )}

              {outstandingRent && (
                <div className="py-4 px-8 md:px-2 lg:px-4">
                  <OutstandingRentDueCard
                    value={outstandingRent.amount}
                    hasAccess={true}
                    month={outstandingRent.month}
                    isLoading={isFetching}
                  />
                </div>
              )}

              {paidRent && (
                <div className="py-4 pl-8 md:pl-2 lg:pl-4">
                  <RevenueCard
                    value={paidRent.amount}
                    hasAccess={true}
                    month={paidRent.month}
                    isLoading={isFetching}
                  />
                </div>
              )}
            </HorizontalScrollSection>
          )
        case 'agent':
        case 'broker':
        case 'other':
          return (
            <HorizontalScrollSection customResponsive={cardsToScroll}>
              {listingsSummary && (
                <div className="py-4 pr-8 md:pr-2 lg:pr-4">
                  <AvailableListingsCard
                    open={listingsSummary.openListings}
                    isLoading={isFetching}
                  />
                </div>
              )}
              {listingsSummary && (
                <div className="py-4 px-8 md:px-2 lg:px-4">
                  <LeadsSummaryCard
                    isLoading={isFetching}
                    leads={listingsSummary.openListingLeads}
                    hasAccess={true}
                  />
                </div>
              )}
              {applicationsSummary && (
                <div className="py-4 pl-8 md:pl-2 lg:pl-4">
                  <ApplicationsSummaryCard
                    inReview={applicationsSummary.inReview}
                    accepted={applicationsSummary.accepted}
                    isLoading={isFetching}
                    hasAccess={userPermissions.isManagingApplications}
                    setShowAppOptInModal={setShowAppOptInModal}
                  />
                </div>
              )}
              {/* Number of views Card */}
            </HorizontalScrollSection>
          )
        default:
          return <div>No buildings or listings yet!</div>
      }
    }
  }

  return (
    <div>
      {managementSummary ? getSummaryCards(userPermissions.role) : <BeatLoader size={5} />}
      {showAppOptInModal && <AppOptInModal onClose={() => setShowAppOptInModal(false)} />}
    </div>
  )
}

export default SummaryHeader
