//  Ideas comes from https://github.com/ekeric13/react-star-ratings
import { useState } from 'react'
import Star from './Star'

interface Props {
  rating?: number
  isStatic?: boolean
  numberOfStars?: number
  fillColor?: string
  emptyColor?: string
  starSpace?: number
  starSize?: number
  onRatingChange?: (rating: number) => void
}

const getOffsetValue = (rating: number): string => {
  const ratingIsInteger = Number.isInteger(rating)
  let offsetValue = '0%'
  if (!ratingIsInteger) {
    const firstTwoDecimals = rating.toFixed(2).split('.')[1].slice(0, 2)
    offsetValue = `${firstTwoDecimals}%`
  }
  return offsetValue
}

const renderStar = (
  fillId: string,
  rating: number,
  numberOfStars: number,
  fillColor: string,
  emptyColor: string,
  starSpace: number,
  starSize: number,
  isStatic: boolean,
): JSX.Element => {
  const numberOfStarsArray = [...Array(numberOfStars)]

  return (
    <>
      {numberOfStarsArray.map((_, index): JSX.Element => {
        let starFillStatus
        if (rating - index - 1 >= 0) {
          starFillStatus = 'full'
        } else if (rating < index) {
          starFillStatus = 'empty'
        } else {
          starFillStatus = 'partial'
        }
        const hasNoRightMargin = index === numberOfStars - 1
        return (
          <Star
            key={index}
            fillId={fillId}
            starRating={index + 1}
            starIndex={index}
            starFillStatus={starFillStatus}
            fillColor={fillColor}
            emptyColor={emptyColor}
            hasNoRightMargin={hasNoRightMargin}
            starSpace={starSpace}
            starSize={starSize}
            isStatic={isStatic}
          />
        )
      })}
    </>
  )
}

const StarRating = ({
  rating = 0,
  numberOfStars = 5,
  isStatic = true,
  fillColor = '#F8B642',
  emptyColor = '#d1d5db',
  starSpace = 5,
  starSize = 20,
  onRatingChange = () => {
    return
  },
}: Props): JSX.Element => {
  const fillId = `starGrad${Math.random().toFixed(15).slice(2)}`
  const [hoverIndex, setHoverIndex] = useState(-1)
  if (rating === null) {
    rating = 0
  }
  return (
    <div className="border-box block align-middle">
      <svg className="absolute fill-current w-0 h-0 z-0">
        <defs>
          <linearGradient id={fillId} x1="0%" y1="0%" x2="100%" y2="0%">
            <stop
              offset="0%"
              className="stop-color-first"
              style={{ stopColor: fillColor, stopOpacity: 1 }}
            />
            <stop
              offset={getOffsetValue(rating)}
              className="stop-color-first"
              style={{ stopColor: fillColor, stopOpacity: 1 }}
            />
            <stop
              offset={getOffsetValue(rating)}
              className="stop-color-final"
              style={{ stopColor: emptyColor, stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              className="stop-color-final"
              style={{ stopColor: emptyColor, stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
      </svg>
      {renderStar(
        fillId,
        rating,
        numberOfStars,
        fillColor,
        emptyColor,
        starSpace,
        starSize,
        isStatic,
      )}
    </div>
  )
}

export default StarRating
