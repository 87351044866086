import Modal from '@components/generic/Modal'
import PositiveNumberInput from '@components/generic/PositiveNumberInput'
import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import {
  currency,
  dateStringToObj,
  formatFullDate,
  formatTenancyDates,
  getFirstDayOfMonth,
} from 'src/lib/utilities'

import 'react-datepicker/dist/react-datepicker.css'
import { Units } from 'src/data'
import { useThunkDispatch } from '@redux/hooks'
// import { getAllUserManagedUnits } from '@redux/actions'
import ApplicantRentSplit from './ApplicantRentSplit'
import { uploadLeaseDocument } from 'src/data/Units'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { segment } from 'src/lib/analytics'
import { updateApplication } from '@redux/actions'
import * as Sentry from '@sentry/nextjs'

interface ApproveApplicationModalProps {
  onClose: any
  application: any
  token: string
  onSuccess: () => void
}

const DatePicker = dynamic(() => import('react-datepicker'))

const inputDivStyles = 'flex flex-col font-semibold my-3 text-left space-y-2'
const chargeDetailStyle = 'p-2 text-left font-normal'

const SectionLayout = ({ heading, children }) => {
  return (
    <div className={`border rounded-2xl my-4 p-7 w-full flex flex-wrap`}>
      {heading ? <h4 className="font-bold mb-2 w-full">{heading}</h4> : <></>}
      {children}
    </div>
  )
}
const ApproveApplicationModal = ({
  onClose,
  application,
  token,
  onSuccess,
}: ApproveApplicationModalProps) => {
  const [rent, setRent] = useState(undefined)
  const [totalRent, setTotalRent] = useState(undefined)

  const [deposit, setDeposit] = useState(undefined)
  const [monthlyDeposit, setMonthlyDeposit] = useState(undefined)
  const [totalDeposit, setTotalDeposit] = useState(undefined)
  const [depositPaidMonthly, setDepositPaidMonthly] = useState(false)

  const [openiglooFeePercent, setOpeniglooFeePercent] = useState(5)
  const [monthlyFee, setMonthlyFee] = useState(undefined)
  const [totalFee, setTotalFee] = useState(undefined)
  const [feePaidOnetime, setFeePaidOnetime] = useState(true)

  const [depositChangedAfterRent, setDepositChangedAfterRent] = useState(false)

  const [startDate, setStartDate] = useState(undefined)
  const [endDate, setEndDate] = useState(undefined)
  const [duration, setDuration] = useState(undefined)
  const [docFile, setDocFile] = useState(null)
  const [superName, setSuperName] = useState(undefined)
  const [superPhone, setSuperPhone] = useState(undefined)
  const [superEmail, setSuperEmail] = useState(undefined)
  const [rentSplit, setRentSplit] = useState(false)
  const [showApproveMessage, setShowApproveMessage] = useState(false)

  const dispatch = useThunkDispatch()

  const [isValid, setIsValid] = useState(false)
  const [totalSplitObj, setTotalSplitObj] = useState({})
  const [isRentSplitValid, setIsRentSplitValid] = useState(false)
  const [isPostingApplication, setIsPostingApplication] = useState(false)

  useEffect(() => {
    if (application) {
      const { offer, expectedMoveIn, expectedMoveOut } = application

      if (expectedMoveOut) {
        setEndDate(dateStringToObj(expectedMoveOut))
      }
    }
  }, [application])

  useEffect(() => {
    if (
      rent &&
      deposit &&
      startDate &&
      endDate &&
      superName &&
      superPhone &&
      (rentSplit ? isRentSplitValid : true)
    ) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [rent, deposit, startDate, endDate, superName, superPhone, rentSplit, isRentSplitValid])

  const minDate = () => {
    const d = new Date()
    d.setDate(1)
    d.setHours(-1)
    return d
  }
  const handleEndDate = (value) => {
    const lastDayOfMonth = new Date(value.getFullYear(), value.getMonth() + 1, 0)
    setEndDate(lastDayOfMonth)
  }

  useEffect(() => {
    if (rent) {
      setOpeniglooFeePercent(5)
      if (duration) {
        setTotalRent(rent * duration)
      } else {
        setTotalRent(rent)
      }
    }
  }, [rent, duration])

  useEffect(() => {
    if (deposit) {
      if (depositPaidMonthly) {
        setMonthlyDeposit((0.92 * deposit) / 60)
        setTotalDeposit(((0.92 * deposit) / 60) * duration)
      } else {
        setMonthlyDeposit(0)
        setTotalDeposit(deposit)
      }
    }
  }, [deposit, depositPaidMonthly])

  useEffect(() => {
    if (openiglooFeePercent) {
      if (!feePaidOnetime) {
        setMonthlyFee(rent * (openiglooFeePercent / 100))
        setTotalFee(rent * (openiglooFeePercent / 100) * duration)
      } else {
        setMonthlyFee(0)
        setTotalFee(rent * (openiglooFeePercent / 100) * duration)
      }
    } else {
      setMonthlyFee(0)
      setTotalFee(0)
    }
  }, [openiglooFeePercent, feePaidOnetime, rent, duration])

  const handleConfirm = async () => {
    segment.trackApplicationApprove(application.id)
    const contributionSplit = []
    Object.keys(totalSplitObj).map((email) => {
      contributionSplit.push({ email, contribution: parseFloat(totalSplitObj[email]) / 100 })
    })

    const leaseData = {
      rent: parseFloat(rent),
      deposit: parseFloat(deposit),
      fee_percent: openiglooFeePercent / 100,
      is_fee_paid_monthly: !feePaidOnetime,
      start: formatTenancyDates(getFirstDayOfMonth(startDate)),
      num_months: parseInt(duration),
      super_name: superName,
      super_phone: superPhone,
      super_email: superEmail,
      is_vasp_enabled: depositPaidMonthly,
      rent_split: rentSplit ? contributionSplit : null,
    }
    setIsPostingApplication(true)
    try {
      const approvedApplicationData = await Units.acceptApplication(
        application.id,
        leaseData,
        token
      )
      dispatch(updateApplication(approvedApplicationData))
      if (docFile) {
        await uploadLeaseDocument(approvedApplicationData.unitId, docFile, token)
      }
      setShowApproveMessage(true)
      setTimeout(() => onSuccess(), 2000)
    } catch (e) {
      Sentry.captureException(e)
      console.error(e)
    }
    setIsPostingApplication(false)
  }

  return (
    <Modal title={'Approve Application'} onClose={() => onClose()}>
      {showApproveMessage ? (
        <div className="w-full p-4">
          <div className="border px-6 py-2 my-3 rounded-lg bg-green-300 text-white">
            Success! This application has been approved. Redirecting...
          </div>
        </div>
      ) : (
        <div className="overflow-y-auto h-full pb-20">
          <div className="flex flex-wrap p-6">
            <SectionLayout heading="Lease Information">
              <div className={inputDivStyles + ' w-1/2 pr-2'}>
                <span>
                  Start Date<span className="text-red-400">*</span>{' '}
                </span>
                <div className="">
                  <DatePicker
                    selected={startDate}
                    minDate={minDate()}
                    onChange={setStartDate}
                    showMonthYearPicker
                    className="border-gray-200 rounded-3xl py-1 px-3 w-full focus:outline-none"
                  />
                </div>
              </div>
              <div className={inputDivStyles + ' w-1/2 pr-2'}>
                <span>
                  Lease Duration (months)<span className="text-red-400">*</span>{' '}
                </span>
                <div>
                  <PositiveNumberInput
                    value={duration}
                    onChange={(e) => {
                      setDuration(e.target.value)
                      if (startDate) {
                        const endMonth = startDate.getMonth() + parseInt(e.target.value)
                        const endMonthNextYear = endMonth % 12
                        const addNumberYears = Math.floor(endMonth / 12)
                        setEndDate(
                          new Date(startDate.getFullYear() + addNumberYears, endMonthNextYear, 0)
                        )
                      }
                    }}
                    className="py-1 px-3 rounded-3xl border w-full border-gray-200 focus:outline-none"
                    placeholder="How long will the lease be for?"
                  />
                </div>
              </div>
              <div className={inputDivStyles + ' w-1/2 mr-2'}>
                <span>End Date</span>
                {endDate && duration ? (
                  <div className="font-normal">{formatFullDate(endDate)}</div>
                ) : null}
              </div>
              <div className="flex-1 mr-2 flex flex-col flex-wrap">
                <span className="font-semibold text-left">
                  Lease Document<span className="text-red-400">*</span>
                </span>
                <input
                  type="file"
                  className="hidden"
                  accept="application/pdf"
                  disabled={isPostingApplication}
                  onChange={(e) => {
                    setDocFile(e.target.files[0])
                  }}
                  id="doc-upload"
                />
                <label
                  className={`primary-button cursor-pointer w-1/2 bg-lightred-300 text-white  text-xs ${
                    !isPostingApplication ? '' : 'opacity-50 cursor-default'
                  }
                }`}
                  htmlFor="doc-upload"
                >
                  {docFile ? docFile.name : 'Upload'}
                </label>
              </div>
            </SectionLayout>
            <SectionLayout heading="Rent">
              <div className={inputDivStyles + ' w-1/2 mr-2'}>
                <span>
                  Monthly Rent<span className="text-red-400">*</span>
                </span>
                <div className="inline-flex items-center">
                  <span className="font-light mr-2">$</span>
                  <PositiveNumberInput
                    numberType="float"
                    decimalPlaces={2}
                    value={rent}
                    onChange={(e) => {
                      setRent(e.target.value)
                      if (!depositChangedAfterRent) {
                        setDeposit(e.target.value)
                      }
                    }}
                    className="py-1 px-3 rounded-3xl border w-full border-gray-200 focus:outline-none"
                    placeholder="Total rent amount ($)"
                  />
                </div>
              </div>
              <div className={inputDivStyles + ' flex-1'}>
                <span>Total Rent</span>
                <div className="inline-flex items-center">
                  <span>{currency(totalRent || 0)}</span>
                </div>
              </div>
            </SectionLayout>
            <SectionLayout heading="Deposit">
              <div className={inputDivStyles + ' w-1/2 mr-2'}>
                <span>
                  Deposit Amount<span className="text-red-400">*</span>
                </span>
                <div className="inline-flex items-center">
                  <span className="font-light mr-2">$</span>

                  <PositiveNumberInput
                    value={deposit}
                    onChange={(e) => {
                      setDeposit(e.target.value)
                      setDepositChangedAfterRent(true)
                    }}
                    className="py-1 px-3 rounded-3xl border w-full border-gray-200 focus:outline-none"
                    placeholder="Total safety deposit amount ($)"
                  />
                </div>
              </div>

              <div className={inputDivStyles + ' flex-1'}>
                <span>
                  <span>Paid Monthly</span>
                </span>
                <div className="inline-flex items-center">
                  <span className="font-normal">
                    Is the deposit paid through VASP contributions?
                    <span className="text-red-400">*</span>
                  </span>
                  <input
                    type="checkbox"
                    checked={depositPaidMonthly}
                    onChange={(e) => setDepositPaidMonthly(e.target.checked)}
                    className="ml-4 rounded-sm"
                  />
                </div>
              </div>

              <div className={inputDivStyles + ' w-1/2 mr-2'}>
                <span>Monthly Deposit</span>
                <div className="inline-flex items-center font-normal">
                  <span>{currency(monthlyDeposit || 0)}</span>
                </div>
              </div>
              <div className={inputDivStyles + ' flex-1'}>
                <span>Total Deposit</span>
                <div className="inline-flex items-center font-normal">
                  <span>{currency(totalDeposit || 0)}</span>
                </div>
              </div>
            </SectionLayout>
            <SectionLayout heading="openigloo Fee">
              <div className={inputDivStyles + ' w-1/2 mr-2'}>
                <span>
                  openigloo Fee Percent<span className="text-red-400">*</span>
                </span>
                <div className="inline-flex items-center">
                  <PositiveNumberInput
                    numberType="float"
                    decimalPlaces={2}
                    value={openiglooFeePercent}
                    onChange={(e) => setOpeniglooFeePercent(e.target.value)}
                    className="py-1 px-3 rounded-3xl border w-full border-gray-200 focus:outline-none"
                    placeholder="What percent of the rent is the openigloo fee ($)"
                  />
                  <span className="font-light mr-2">%</span>
                </div>
              </div>
              <div className="flex flex-1 items-center my-3 font-normal">
                <span>
                  Is the total annual fee paid at once?<span className="text-red-400">*</span>
                </span>
                <input
                  type="checkbox"
                  checked={feePaidOnetime}
                  onChange={(e) => setFeePaidOnetime(e.target.checked)}
                  className="ml-4 rounded-sm"
                />
              </div>
              <div className={inputDivStyles + ' w-1/2'}>
                <span>Monthly openigloo Fee</span>
                <div className="inline-flex items-center font-normal">
                  <span>{currency(monthlyFee || 0)}</span>
                </div>
              </div>
              <div className={inputDivStyles + ' flex-1'}>
                <span>Total openigloo Fee</span>
                <div className="inline-flex items-center font-normal">
                  <span>{currency(totalFee || 0)}</span>
                </div>
              </div>
            </SectionLayout>

            <SectionLayout heading="Lease Summary">
              <table className="table-auto w-full">
                <thead>
                  <tr className="border-b bg-indigo-200 w-full ">
                    <th className={`${chargeDetailStyle} font-semibold`}></th>
                    <th className={`${chargeDetailStyle} font-semibold text-right`}>Monthly</th>
                    <th className={`${chargeDetailStyle} font-semibold text-right`}>
                      One-time Payment
                    </th>
                    <th className={`${chargeDetailStyle} font-semibold text-right`}>
                      Total
                      <div className="font-normal text-sm">(over lease duration)</div>
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  <tr className=" border-b bg-gray-50">
                    <th className={`${chargeDetailStyle}`}>Rent</th>
                    <th className={`${chargeDetailStyle} text-right`}>
                      {currency(rent ? rent : 0)}
                    </th>
                    <th className={`${chargeDetailStyle} text-right`}>{currency(0)}</th>

                    <th className={`${chargeDetailStyle} text-right`}>
                      {currency(totalRent ? totalRent : 0)}
                    </th>
                  </tr>
                  <tr className=" border-b bg-white">
                    <th className={`${chargeDetailStyle}`}>
                      Deposit {depositPaidMonthly ? '(VASP enabled)' : null}
                    </th>
                    <th className={`${chargeDetailStyle} text-right`}>
                      {currency(monthlyDeposit ? monthlyDeposit : 0)}
                    </th>
                    <th className={`${chargeDetailStyle} text-right`}>
                      {currency(depositPaidMonthly ? 0 : deposit || 0)}
                    </th>
                    <th className={`${chargeDetailStyle} text-right`}>
                      {currency(totalDeposit ? totalDeposit : 0)}
                    </th>
                  </tr>
                  <tr className=" border-b bg-gray-50">
                    <th className={`${chargeDetailStyle}`}>
                      openigloo Fee ({openiglooFeePercent}% of rent)
                    </th>
                    <th className={`${chargeDetailStyle} text-right`}>
                      {currency(monthlyFee ? monthlyFee : 0)}
                    </th>
                    <th className={`${chargeDetailStyle} text-right`}>
                      {currency(feePaidOnetime ? totalFee || 0 : 0)}
                    </th>

                    <th className={`${chargeDetailStyle} text-right`}>
                      {currency(totalFee ? totalFee : 0)}
                    </th>
                  </tr>
                  <tr className=" border-b bg-indigo-100">
                    <th className={`${chargeDetailStyle} font-semibold`}>TOTAL</th>
                    <th className={`${chargeDetailStyle} text-right`}>
                      {currency(
                        (parseFloat(rent) || 0) +
                          (parseFloat(monthlyDeposit) || 0) +
                          (parseFloat(monthlyFee) || 0)
                      )}
                    </th>
                    <th className={`${chargeDetailStyle} text-right`}>
                      {currency(
                        parseFloat(depositPaidMonthly ? 0 : totalDeposit || 0) +
                          parseFloat(feePaidOnetime ? totalFee || 0 : 0)
                      )}
                    </th>

                    <th className={`${chargeDetailStyle} text-right`}>
                      {currency(
                        (parseFloat(totalRent) || 0) +
                          (parseFloat(totalDeposit) || 0) +
                          (parseFloat(totalFee) || 0)
                      )}
                    </th>
                  </tr>
                </tbody>
              </table>
            </SectionLayout>

            {application && application.applicants && application.applicants.length > 1 && (
              <SectionLayout heading="Rent Split">
                <div className="w-full flex items-center my-3">
                  <span>
                    Will the rent be split across tenants?<span className="text-red-400">*</span>
                  </span>
                  <input
                    type="checkbox"
                    checked={rentSplit}
                    onChange={(e) => setRentSplit(e.target.checked)}
                    className="ml-4 rounded-sm"
                  />
                </div>
                {rentSplit && (
                  <ApplicantRentSplit
                    application={application}
                    setIsRentSplitValid={setIsRentSplitValid}
                    totalSplitObj={totalSplitObj}
                    setTotalSplitObj={setTotalSplitObj}
                    rent={rent}
                  />
                )}
              </SectionLayout>
            )}
            <SectionLayout heading="Superintendent Information">
              <div className="w-full flex flex-wrap">
                <span className="font-semibold text-left">
                  Superintendent Name<span className="text-red-400">*</span>
                </span>
                <input
                  value={superName}
                  onChange={(e) => setSuperName(e.target.value)}
                  className="w-full py-1 px-3 my-2 rounded-2xl border border-gray-200"
                  placeholder="Name of the superintendent"
                />
              </div>
              <div className="w-full flex flex-wrap">
                <span className="font-semibold text-left">
                  Superintendent Phone<span className="text-red-400">*</span>
                </span>
                <input
                  value={superPhone}
                  onChange={(e) => setSuperPhone(e.target.value)}
                  className="w-full py-1 px-3 my-2 rounded-2xl border border-gray-200"
                  placeholder="How can the superintendent be contacted"
                />
              </div>
              <div className="w-full flex flex-wrap">
                <span className="font-semibold text-left">Superintendent Email</span>
                <input
                  value={superEmail}
                  onChange={(e) => setSuperEmail(e.target.value)}
                  className="w-full py-1 px-3 my-2 rounded-2xl border border-gray-200"
                  placeholder="Current superintendent email address"
                />
              </div>
            </SectionLayout>
          </div>
          <div className="inline-flex py-4 mb-24 md:py-0 w-full justify-evenly mt-3 md:static bottom-0">
            <button
              className={`primary-button text-white bg-bluegray-400 rounded-full w-1/3 max-w-210-px ${
                isValid ? '' : 'opacity-50 cursor-default'
              }`}
              onClick={() => handleConfirm()}
              disabled={!isValid || isPostingApplication}
            >
              {isPostingApplication ? (
                <AiOutlineLoading3Quarters className="animate-spin" />
              ) : (
                'Confirm'
              )}
            </button>
            <button
              className="primary-button text-white bg-lightred-300 rounded-full w-1/3 max-w-210-px"
              onClick={() => onClose()}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </Modal>
  )
}

export default ApproveApplicationModal
