import Head from 'next/head'

import Navbar from './navbar'
import Footer from './footer'
import { useEffect } from 'react'
import { useAuth } from '@stores/authContext'
import { useDispatch } from 'react-redux'
import { setCityAmenities } from '@redux/actions'
import { useAppSelector } from '@redux/hooks'

export default function Layout({
  showLoginButtons = true,
  children,
}: {
  showLoginButtons?: boolean
  children: JSX.Element
}) {
  const titleText = 'Connect with NYC Renters | openigloo'
  const description =
    "Join New York City's First Tenant-Owner Marketplace. On openigloo, property owners can connect directly with renters with verified payment history."
  const url = 'https://www.openigloo.com/owners'
  const cover = '/owners-landing-cover.png'

  const { authReady, user } = useAuth()
  const dispatch = useDispatch()
  const { cityAmenities } = useAppSelector((state) => state.currentBuilding)

  useEffect(() => {
    if (authReady && user) {
      if (!(cityAmenities && cityAmenities.length > 0)) {
        dispatch(setCityAmenities('nyc'))
      }
    }
  }, [authReady, user, cityAmenities])

  return (
    <>
      <Head>
        <title>{titleText}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={titleText} />
        <meta property="og:description" content={description} />
        <meta property="twitter:title" content={titleText} />
        <meta property="twitter:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={cover} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:image" content={cover} />
        <meta name="apple-itunes-app" content="app-id=1521697583" />
        <meta name="google-play-app" content="app-id=com.openigloo" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="msapplication-TileColor" content="#070052" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <div className="h-screen flex flex-col overflow-y-scroll">
        <Navbar />
        <main className="flex-grow">{children}</main>
        <Footer />
      </div>
    </>
  )
}
