import { useEffect, useState } from 'react'
import { debounce } from 'lodash'

const usePagination = ({ data, pageSizes, setData, resetData, cleanupData }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(pageSizes[0])
  const [currentParams, setCurrentParams] = useState<any>({})
  const lastPage = Math.ceil(data.count / pageSize)

  const getCurrentPage = (force?: boolean) => {
    if (force || !data.items[currentPage]) {
      setData({
        page: currentPage,
        params: { ...currentParams, pageSize },
      })
    }
    if (currentPage > 1 && (force || !data.items[currentPage - 1])) {
      setData({
        page: currentPage - 1,
        params: { ...currentParams, pageSize },
      })
    }
    if (currentPage < lastPage && (force || !data.items[currentPage + 1])) {
      setData({
        page: currentPage + 1,
        params: { ...currentParams, pageSize },
      })
    }
  }

  const getDataOnChange = () => {
    resetData()
    if (currentPage === 1) {
      getCurrentPage(true)
    } else {
      setCurrentPage(1)
    }
  }

  useEffect(() => {
    getCurrentPage()
    const pagesToCleanUp = Object.keys(data.items).filter(
      (item) => parseInt(item) > currentPage + 2 || parseInt(item) < currentPage - 2
    )
    if (pagesToCleanUp.length > 0) cleanupData(pagesToCleanUp)
  }, [currentPage, lastPage])

  useEffect(() => {
    getDataOnChange()
  }, [pageSize])

  const debouncedFetchData = debounce(() => {
    getDataOnChange()
  }, 500)

  useEffect(() => {
    debouncedFetchData()
  }, [currentParams])

  return {
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    currentParams,
    setCurrentParams,
  }
}

export default usePagination
