import React from 'react'
import Select from 'react-select'

const OISelect = ({
  withIcon = false,
  Icon = null,
  customHeadCss = '',
  isDisabled = false,
  ...rest
}) => {
  const selectStyles = {
    container: (provided) => ({
      ...provided,
      width: '100%',
      cursor: 'pointer',
      padding: '1px 10px',
      borderColor: '#80244F',
    }),
    control: (provided) => ({
      ...provided,
      width: '100%',
      border: '0px',
      borderRadius: '0px',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      borderColor: '#80244F',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: '#FBE5EC',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      backgroundColor: isFocused && '#FBE5EC',
      color: isSelected ? '#e23668' : '',
      ':hover': {
        backgroundColor: '#FBE5EC',
      },
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      ':hover': {
        backgroundColor: '#FBE5EC',
      },
    }),
    menu: (styles) => ({
      ...styles,
      width: '95%',
    }),
    menuList: (styles) => ({
      ...styles,
      maxHeight: '350px',
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      color: '#F093B2',
      cursor: 'pointer',
      width: '90%',
      '&:hover': {
        color: '#80244F',
      },
    }),
  }
  const stylesWithIcon = {
    menu: (provided) => ({
      ...provided,
      left: '-20px',
    }),
  }

  return (
    <div
      className={`flex flex-row w-full items-center justify-start border border-light-40 rounded-3xl ${
        isDisabled ? 'bg-light-20' : 'bg-white cursor-pointer'
      } ${customHeadCss}`}
    >
      {withIcon ? Icon : null}
      <Select
        {...rest}
        isDisabled={isDisabled}
        styles={withIcon ? { ...selectStyles, ...stylesWithIcon } : selectStyles}
      />
    </div>
  )
}

export default OISelect
