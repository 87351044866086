import Pill from '@components/generic/Pill'
import { titleCase } from 'src/lib/utilities'

const FormSection = ({
  title = '',
  status = null,
  description = null,
  children,
  className = '',
  canEdit = true,
}: {
  title?: string
  status?: string | null
  description?: React.ReactNode | null
  children: React.ReactNode
  className?: string
  canEdit?: boolean
}) => {
  return (
    <div
      className={`${canEdit ? 'p-6 shadow-lg bg-white rounded-2xl mb-8' : ''} ${className}`}
      id={title ?? ''}
    >
      {title || status ? (
        <div className="flex flex-row items-center justify-start w-full mb-2">
          {title ? <span className="text-2xl text-primary font-semibold">{title}</span> : null}
          {status ? (
            <Pill
              label={titleCase(status)}
              pillStyle="ml-4 flex items-center py-1"
              color={status == 'draft' ? 'yellow' : 'published'}
            />
          ) : null}
        </div>
      ) : null}
      {description ? <div className="mb-4">{description}</div> : null}
      {children}
    </div>
  )
}

export default FormSection
