import React from 'react'

import { titleCase } from 'src/lib/utilities'
import { Suggestion } from '@openigloo/types'

type PopoverMenuProps = {
  getMenuProps: () => JSX.Element
  getItemProps: (d: any) => JSX.Element
  highlightedIndex: number | null
  suggestions: Array<Suggestion>
}

const PopoverMenu = ({
  getMenuProps,
  getItemProps,
  highlightedIndex,
  suggestions,
}: PopoverMenuProps): JSX.Element => {
  return (
    <ul
      {...getMenuProps()}
      className="relative rounded-b-xl overflow-hidden w-full z-40 bg-white border-r-2 border-l-2 border-b-2 border-light-20 shadow-sm"
    >
      {suggestions.map((item, index) => (
        <li
          {...getItemProps({
            item,
            index,
          })}
          key={item.addressSlug}
          className={`relative block h-auto px-5 py-2 cursor-pointer text-left ${
            highlightedIndex === index ? ' bg-bluegray-300 text-white' : null
          }`}
        >
          {titleCase(item.address)}
        </li>
      ))}
    </ul>
  )
}

export default PopoverMenu
