import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useAppSelector } from '@redux/hooks'
import { useDispatch } from 'react-redux'

import { Building, Suggestion } from '@openigloo/types'
import { SearchBar, BuildingList } from '@components/index'

import { segment } from 'src/lib/analytics'
import { pluralizeString, titleCase } from 'src/lib/utilities'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

import { getBuilding } from 'src/data/Buildings'
import { addManagedBuilding, removeManagedBuilding } from 'src/data/Subscription'
import { addAdditionalBuildings } from '@redux/actions'
// import { getAllUserManagedBuildings } from '@redux/actions'
interface AddBuildingsFormProps {
  submitBuildings?: () => void
  onSubscribeConfirm: () => void
  updateSubscription?: boolean
  redirect?: boolean
  showSuccessOnComplete?: boolean
  showFullScreen?: boolean
}

const AddBuildingsForm = ({
  submitBuildings,
  onSubscribeConfirm,
  updateSubscription = false,
  redirect = true,
  showSuccessOnComplete = true,
  showFullScreen = true,
}: AddBuildingsFormProps) => {
  const { token, userManagedBuildings } = useAppSelector((state) => state.currentUser)

  const [step, setStep] = useState<number>(0)
  const [isLoading, setIsLoading] = useState(false)

  const [buildings, setBuildings] = useState<Building[]>([])
  const [units, setUnits] = useState<number>(0)

  const [totalSubscriptionPrice, setTotalSubscriptionPrice] = useState<number>(null)
  const [additionalCost, setAdditionalCost] = useState(0)

  // const [inactiveBlds, setInactiveBlds] = useState<Building[]>([])

  const [showSubscribeMessage, setShowSubscribeMessage] = useState(false)
  const [subscribeMessage, setSubscribeMessage] = useState(null)

  const router = useRouter()
  const dispatch = useDispatch()

  // const getInactiveBuildings = async (token) => {
  //   const inactiveBlgs = await UserProfile.getUserManagedBuildings(token, 1, false)
  //   setInactiveBlds([...inactiveBlgs.buildings])
  //   setBuildings([...buildings, ...inactiveBlgs.buildings])
  // }

  // useEffect(() => {
  //   if (token) {
  //     getInactiveBuildings(token)
  //   }
  // }, [token])

  useEffect(() => {
    setUnits(buildings.map((building) => building.noUnits || 0).reduce((a, b) => a + b, 0))
  }, [buildings])

  const onSuggestionSelected = async (item: Suggestion) => {
    if (buildings.map((b) => b.id).includes(item.buildingId)) {
      return
    }
    const building = await getBuilding(item.buildingId)
    if (building.productBuilding.isClaimed) {
      alert('Someone else has already claimed this building.')
      return
    }
    const updatedBuildings = [...buildings, building]
    setBuildings(updatedBuildings)
  }

  const onSubmitBuildings = async () => {
    setIsLoading(true)
    const added = await Promise.all(
      buildings.map(async (building) => {
        return await addManagedBuilding(building.productBuilding.id)
      })
    )
    // const activeSubPrice = await getSubscriptionPrice(token)
    // const inactiveSubPrice = await getSubscriptionPrice(token, false)
    // setAdditionalCost(inactiveSubPrice.amount)
    // setTotalSubscriptionPrice(activeSubPrice.amount + inactiveSubPrice.amount)
    // if (!updateSubscription && submitBuildings) {
    //   submitBuildings()
    // }
    // setIsLoading(false)
    // setStep(1)
    if (!added.includes(undefined)) {
      setSubscribeMessage('Your subscription was successful! Now redirecting...')
      dispatch(addAdditionalBuildings(added))
      if (redirect) {
        setTimeout(() => router.push('/home'), 2000)
      }
      onSubscribeConfirm()
    } else {
      setSubscribeMessage('Something went wrong. Please try again later.')
    }
    setIsLoading(false)
    setShowSubscribeMessage(true)
  }

  const openBuilding = (bld: Building) => {
    window.open(
      `https://www.openigloo.com/building/${bld.productBuilding.city}/${bld.id}/${bld.addressSlug}`
    )
  }

  return (
    <div className="w-full">
      {step === 0 && (
        <>
          <div
            className={`max-w-xl ${
              buildings.length == 0 ? (showFullScreen ? 'min-h-screen-75' : 'h-24') : 'h-max'
            }`}
          >
            <div className="text-left mb-4">
              <h3 className="text-lg font-semibold">Add Your Buildings</h3>
              <span>
                Search for and add buildings below where you own, manage or represent units
              </span>
            </div>
            <SearchBar
              onSuggestionSelected={(item) => {
                segment.trackOnboardingAddBuildingTap(item.address)
                onSuggestionSelected(item)
              }}
            />
          </div>
          <div className="pt-8 pb-2 flex flex-wrap">
            {/* <BuildingList
              buildings={userManagedBuildings}
              onRemove={removeUserManagedBuilding}
              onClick={openBuilding}
            /> */}
            <BuildingList
              buildings={[...buildings]}
              onRemove={async (bld) => {
                const blds = [...buildings]
                const filteredBldgs = blds.filter((building) => building.id !== bld.id)
                setBuildings(filteredBldgs)
                segment.trackOnboardingRemoveBuildingTap(bld.fullAddress)
              }}
              onClick={openBuilding}
            />
          </div>
          {showSubscribeMessage ? (
            showSuccessOnComplete ? (
              <div
                className={` p-8 rounded-md  border ${
                  subscribeMessage && subscribeMessage.includes('wrong')
                    ? 'border-red-200 bg-red-50'
                    : 'border-green-200 bg-green-50'
                }`}
              >
                {subscribeMessage}
              </div>
            ) : null
          ) : (
            buildings?.length > 0 && (
              <button
                className={`primary-button bg-lightred-300 text-white mx-auto ${
                  isLoading ? 'opacity-50' : ''
                }`}
                onClick={() => {
                  onSubmitBuildings()
                  segment.trackOnboardingFreeSubscription(buildings.length, updateSubscription)
                }}
              >
                {`Claim ${pluralizeString('Building', buildings.length)} ${
                  units < 1 ? '' : `(${units} units)`
                }`}
                {isLoading && <AiOutlineLoading3Quarters className="ml-2 animate-spin" size={18} />}
              </button>
            )
          )}
        </>
      )}
      {step === 1 && (
        <div className="">
          <div className="text-lg font-semibold text-left mb-4 px-4">
            Claim following {updateSubscription && 'additional'} buildings:
          </div>
          {buildings.map((building) => (
            <div className="flex flex-row justify-between px-4 mb-4" key={building.id}>
              <span className="text-blue-300 font-semibold">{titleCase(building.fullAddress)}</span>
              <div className="text-right text-blue-300 font-semibold inline-flex space-x-3">
                <span>{pluralizeString('unit', building.noUnits || 0)}</span>
                <div className="border-l border-blue-300" />
                <span>${building.noUnits && building.noUnits > 19 ? building.noUnits : 20}</span>
              </div>
            </div>
          ))}
          <div className="flex flex-row justify-between px-4 mb-4">
            <span className="font-bold">Total:</span>
            <span className="font-bold">
              {`$${updateSubscription ? additionalCost : totalSubscriptionPrice} / month`}
            </span>
          </div>
          <div className="w-full flex justify-end">
            <button
              onClick={() => {
                setStep(0)
                segment.trackOnboardingBackTap()
              }}
              className="primary-button bg-gray-500 text-white mr-3"
            >
              Back
            </button>
            <button
              className="primary-button bg-lightred-300 text-white"
              onClick={() => {
                onSubscribeConfirm()
                segment.trackOnboardingCheckoutTap()
              }}
            >
              {`Subscribe for ${
                updateSubscription ? 'additional $' + additionalCost : '$' + totalSubscriptionPrice
              } / month`}{' '}
            </button>
          </div>
          {updateSubscription && (
            <div>
              By clicking on &quot;Subscribe&quot; you will automatically be charged the additional
              prorated amount to the original payment method on file.{' '}
            </div>
          )}
          <div className="text-base font-semibold text-left mt-8">
            For more than 500 units, contact us through our support chat or at{' '}
            <a className="text-blue-300" href="mailto:info@openigloo.com">
              info@openigloo.com
            </a>{' '}
            for volume discounts.
          </div>
        </div>
      )}
    </div>
  )
}

export default AddBuildingsForm
