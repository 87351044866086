import React, { useState } from 'react'
import Link from 'next/link'
import { ApplicationStatusPill, FormSection, Section } from '@components/.'
import Table from '@components/table'
import { ColDef } from 'ag-grid-community'
import { formatFullDate } from 'src/lib/utilities'
import { isMobile as rddIsMobile } from 'react-device-detect'

interface ApplicationsListProps {
  currentUnitApplications: any[]
}

const ApplicationsList = ({ currentUnitApplications }: ApplicationsListProps) => {
  const [columnDefs] = useState<ColDef[]>([
    {
      field: '',
      headerName: 'Name',
      autoHeight: true,
      cellRenderer: ({ data }) => (
        <span className="font-semibold block my-2">{`${data.applicantNames[0]} ${
          data.applicantNames.length > 1 ? `(+${data.applicantNames.length - 1})` : ''
        }`}</span>
      ),
    },
    {
      field: '',
      headerName: 'Status',
      cellRenderer: ({ data }) => <ApplicationStatusPill status={data.status} />,
    },
    {
      field: '',
      headerName: 'Date of application',
      cellRenderer: ({ data }) => <span>{formatFullDate(new Date(data.atSubmitted))}</span>,
    },
    {
      field: '',
      headerName: '',
      cellRenderer: ({ data }) => (
        <Link
          href={`/buildings/${data.buildingId}/unit/${data.unitId}/application/${data.id}`}
          passHref
        >
          <a className="bg-lightred-300 text-white px-3 py-1 rounded-full">Manage application</a>
        </Link>
      ),
    },
  ])

  const [mobileColumnDefs] = useState<ColDef[]>([
    {
      field: '',
      headerName: 'Name',
      autoHeight: true,
      cellRenderer: ({ data }) => (
        <div className="my-4">
          <span className="block text-sm font-semibold">{`${data.applicantNames[0]} ${
            data.applicantNames.length > 1 ? `(+ ${data.applicantNames.length - 1})` : ''
          }`}</span>
          <ApplicationStatusPill status={data.status} />
        </div>
      ),
    },
    {
      field: '',
      headerName: '',
      cellRenderer: ({ data }) => (
        <div className="mt-4">
          <Link
            href={`/buildings/${data.buildingId}/unit/${data.unitId}/application/${data.id}`}
            passHref
          >
            <a className="bg-lightred-300 text-white px-3 py-1 rounded-full">Manage application</a>
          </Link>
        </div>
      ),
    },
  ])

  return (
    <FormSection
      title={`Total (${currentUnitApplications?.length}) applications`}
      canEdit={false}
      className="mb-6"
    >
      {currentUnitApplications && currentUnitApplications.length > 0 ? (
        <Table
          rowData={currentUnitApplications}
          columnDefs={rddIsMobile ? mobileColumnDefs : columnDefs}
          hideHeader={rddIsMobile}
        />
      ) : (
        <Section customStyle="mx-2">
          <div>No applications yet!</div>
        </Section>
      )}
    </FormSection>
  )
}

export default ApplicationsList
