import React from 'react'
import { AiTwotoneStar } from '@react-icons/all-files/ai/AiTwotoneStar'
import round from 'lodash/round'

interface ShortRatingProps {
  value: number
  count?: number | string
  titleStyle?: string
  iconFamily?: string
  containerStyle?: string
  countStyle?: string
  starSize?: number
}
const ShortRating = ({
  value,
  count = '',
  titleStyle,
  containerStyle,
  countStyle,
  starSize = 18,
}: ShortRatingProps): JSX.Element => {
  const displayValue = round(value, 1)
  return (
    <div className={`inline-flex items-center ${containerStyle}`}>
      <AiTwotoneStar size={starSize} className="text-yellow-300 mr-1 my-auto" />
      <div className="text-left">
        <data className={titleStyle} value={value}>
          {displayValue}
        </data>
        <data className={`ml-1 text-gray-400 font-thin ${countStyle}`} value={count}>
          {!!count ? `(${count})` : `(0)`}
        </data>
      </div>
    </div>
  )
}

export default ShortRating
