import BeatLoader from 'react-spinners/BeatLoader'

const Loading = (): JSX.Element => {
  return (
    <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-col justify-center text-center">
      <BeatLoader size={9} color="#E23668" />
    </div>
  )
}

export default Loading
