import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { useMemo } from 'react'
import { ColDef, GridReadyEvent } from 'ag-grid-community'
import React from 'react'

interface TableProps extends AgGridReactProps {
  hideHeader?: boolean
}

// eslint-disable-next-line react/display-name
const Table = React.memo(({ hideHeader = false, rowData, columnDefs, ...props }: TableProps) => {
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), [])
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: false,
      resizable: true,
      minWidth: 100,
      suppressMovable: true,
    }
  }, [])

  const onGridReady = (e: GridReadyEvent) => {
    e.api.setDomLayout('autoHeight')
    e.api.sizeColumnsToFit()
    hideHeader && e.api.setHeaderHeight(0)
  }

  return (
    <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine ">
        <AgGridReact
          defaultColDef={defaultColDef}
          onGridReady={onGridReady}
          onGridSizeChanged={(e) => e?.api?.sizeColumnsToFit?.()}
          rowData={rowData}
          columnDefs={columnDefs}
          {...props}
        ></AgGridReact>
      </div>
    </div>
  )
})

export default Table
