import { ApplicationStatus } from 'src/@types/manager'

const ApplicationStatusPill = ({ status }: { status?: ApplicationStatus }) => {
  const common = 'rounded-2xl px-4 py-0.5 border text-xs'

  switch (status) {
    case 'accepted':
      return (
        <span className={`${common}  border-green-75  text-green-400 bg-green-50`}>Accepted</span>
      )
    case 'rejected':
      return (
        <span className={`${common}  border-light-40  text-dark-600 bg-light-10`}>Rejected</span>
      )
    case 'withdrawn':
      return (
        <span className={`${common}  border-gray-100  text-gray-400 bg-gray-50`}>Withdrawn</span>
      )
    default:
      return (
        <span className={`${common} border-lightred-75  text-lightred-300 bg-lightred-50`}>
          In review
        </span>
      )
  }
}

export default ApplicationStatusPill
