import React from 'react'

type SectionSubheaderType = {
  style?: string
  children?: React.ReactChild
  required?: boolean
}

export default function SectionSubheader({
  children = '',
  style = '',
  required = false,
}: SectionSubheaderType) {
  return (
    <span className={`text-base font-semibold ${style}`}>
      {children}
      {required && <span style={{ color: 'red' }}> *</span>}
    </span>
  )
}
