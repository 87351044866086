import { AiOutlinePlus } from 'react-icons/ai'
import { useRouter } from 'next/router'
import { useAuth } from 'src/stores/authContext'
import { segment } from 'src/lib/analytics'
import { useAppSelector } from '@redux/hooks'

const CreateListingButton = () => {
  const { userPermissions } = useAuth()
  const router = useRouter()
  const { chatlioReady } = useAppSelector((state) => state.currentUser)

  const configureChatlio = () => {
    // segment.trackCreateListing()
    // @ts-expect-error
    const chatlio = window._chatlio || []
    if (chatlioReady && chatlio && chatlio.isShown && chatlio.isShown()) {
      chatlio.configure({
        requireInfoSubmitBtn: 'Yes!',
        requireInfoGreeting:
          'Posting listings is a part of openigloo’s leasing platform. Join other NYC owners and managers who are sharing listings, screening applications, and processing payments on openigloo. Would you like to speak to a real person at openigloo to learn more?',
        autoResponseMessage:
          'Posting listings is a part of openigloo’s leasing platform. Join other NYC owners and managers who are sharing listings, screening applications, and processing payments on openigloo. Would you like to speak to a real person at openigloo to learn more?',
      })
    }
  }

  const openChat = () => {
    // @ts-expect-error
    const chatlio = window._chatlio || []
    if (chatlioReady && chatlio && chatlio.isShown && chatlio.isShown()) {
      chatlio.open()
    }
  }

  return (
    <button
      className="border border-light-40 bg-white primary-button text-lightred-500 sm:px-3.5 sm:py-2 text-sm sm:w-48 w-max"
      onClick={() => {
        router.push({
          query: { ...router.query },
          pathname: '/listing',
        })
      }}
    >
      <AiOutlinePlus size={20} className="mr-2" />
      <span>Create New Listing</span>
    </button>
  )
}

export default CreateListingButton
