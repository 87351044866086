
import { AiOutlineClose } from 'react-icons/ai'
import { ActionButton, Separator, StarRating } from '..'
import logo from '@public/logo.png'
import Image from 'next/image'
import { FiThumbsDown, FiThumbsUp } from 'react-icons/fi'
import { Review } from 'src/@types/manager'
import { useEffect, useState } from 'react'

type Props = {
  onClose: () => void
  review: Review
}

function EmbedReviewModal({onClose, review}:Props){
  const [showNotification, setShowNotification] = useState(false)
  
  useEffect(() => {
    if(showNotification){
      setTimeout(() => {
        setShowNotification(false)
      }, 3000)
    }
  },[showNotification])

  return(
    <div className="fixed bg-black bg-opacity-80 top-0 bottom-0 left-0 z-[60] w-full sm:h-screen">
      <div
        className={`flex flex-col slide-up sm:w-2/3 w-full sm:my-32 sm:mx-auto sm:max-w-screen-lg relative
        bg-white sm:rounded-t-2xl sm:rounded-b-2xl h-screen sm:h-max sm:max-h-90 sm:overflow-hidden overflow-auto`}
      >
        <div className='bg-light-10 p-8'>
          <div className='flex justify-between items-center'>
            <div className='text-dark-900 text-xl font-semibold'>Embed Review</div>
            <div onClick={onClose} className="hover:cursor-pointer">
              <AiOutlineClose color={'#E23668'} size={20} />
            </div>
          </div>
          <div className='text-dark-700 pt-2'>Add this review by copying the code below into your website.</div>
        </div>
        <div className='p-8 bg-white'>
          <div className='sm:flex sm:space-x-4 sm:space-y-0 space-y-4 relative'>
            <div className='border border-light-40 w-full rounded-full px-4 py-2 leading-8 whitespace-nowrap text-ellipsis overflow-hidden text-dark-900 select-none'>
              {`<iframe src='https://www.openigloo.com/reviewIframe/${review.id}' height="392px" style="border:none;"></iframe>`}
            </div>
            <ActionButton customStyle='m-0 py-3 !px-6 whitespace-nowrap' label='Copy Code' 
              onClick={() => {setShowNotification(true); navigator.clipboard.writeText(`<iframe src='https://www.openigloo.com/reviewIframe/${review.id}' 
                id='${review.id}' style='border:none; height:392px; border-radius: 8px'></iframe>`)}}/>
          </div>
          <div className='border-light-40 border rounded-lg overflow-auto mx-auto mt-8 sm:w-2/5 w-full'>
            <div className='bg-light-10 px-4 py-4'>
              <div className='flex justify-between'>
                <div className='text-blue-500 font-semibold mr-12'>{review.building.address}</div>
                <div className='w-2/6'>
                  <a href="https://www.openigloo.com" target="_blank" rel="noreferrer">
                    <Image src={logo} alt="openigloo" />
                  </a> 
                </div>
              </div>
              <div className='flex items-center justify-between'>
                <div className='flex items-center'>
                  <StarRating rating={review.rating} numberOfStars={1} starSize={14} /> 
                  <div className='text-dark-900 text-xs font-semibold px-2'>{review?.rating?.toFixed(2)}</div>
                </div>
                <div className="text-xs text-dark-700 flex items-center">
                  <div>{review.createdTime}</div>
                  <div className="pl-4">
                    {review.isCurrentTenant ? 'Current Tenant' : 'Former Tenant'}
                  </div>
                </div>
              </div>
            </div>
            <Separator customStyle='my-0'/>
            <div className='px-4 pb-8 bg-white'>
              <div className='flex pt-4 justify-between items-center'>
                <div>
                  <StarRating rating={review.rating} starSize={20} />
                  <div className="font-semibold text-dark-900 pt-2">{review.title}</div>
                </div>
              </div>
              <div className="pt-2 space-y-2">
                {review.approveOwner && (
                  <div className="flex items-center pr-4 text-green-400">
                    <FiThumbsUp />
                    <div className="pl-1 text-sm">Approves owner</div>
                  </div>
                )}
                {review.approveOwner === false && (
                  <div className="flex items-center pr-4 text-red-400">
                    <FiThumbsDown />
                    <div className="pl-1 text-sm">Does not approve owner</div>
                  </div>
                )}
                {review.recommendBuilding && (
                  <div className="flex items-center pr-4 text-green-400">
                    <FiThumbsUp />
                    <div className="pl-1 text-sm">Recommends Building</div>
                  </div>
                )}
                {review.recommendBuilding === false && (
                  <div className="flex items-center pr-4 text-red-400">
                    <FiThumbsDown />
                    <div className="pl-1 text-sm">Does not recommend building</div>
                  </div>
                )}
              </div>
            </div>
            <Separator customStyle='my-0'/>
            <div className='p-4 text-center'>
              <a target="_blank" rel="noreferrer" className='underline text-bluegray-300 text-sm'
                href={`https://www.openigloo.com/building/${review.building.cityId}/${review.building.id}/${review.building.slug}`}>
                Read all reviews on openigloo
              </a>
            </div>
          </div>
        </div>
      </div>
      {showNotification && 
        <div className='absolute slide-left top-4 right-4 bg-white rounded-lg border-l-8 border-green-400 p-4 pr-8'>
          <div className='text-dark-900 font-semibold'>Code Copied</div>
          <div className='text-dakr-700 pt-2'>You can paste the code into your website.</div>
        </div>
      }
    </div>
  )
}

export default EmbedReviewModal