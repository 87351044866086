import React, { useEffect, useRef, useState } from 'react'
import useOnClickOutside from 'use-onclickoutside'

interface TooltipProps {
  description: any
  children: JSX.Element
  position?: 'top' | 'bottom' | 'left' | 'right' | null
  customStyle?: string
}

const Tooltip = ({ children, description, position = 'top', customStyle = null }: TooltipProps) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [hasClicked, setHasClicked] = useState(false)
  const ref = useRef(null)
  useOnClickOutside(ref, () => setShowTooltip(false))

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setShowTooltip(false)
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  return (
    <div className="relative z-50">
      {showTooltip && (
        <div ref={ref}
          className={`border bg-white rounded-lg w-48 shadow-md text-xs p-2 absolute ${
            position == 'top' ? 'top-6' : ''
          } ${customStyle}`}
        >
          {description}
        </div>
      )}
      <div
        className="hover:cursor-pointer"
        // onMouseOver={() => setShowTooltip(true)}
        // onMouseLeave={() => {
        //   if (!hasClicked) {
        //     setShowTooltip(false)
        //   }
        // }}
        onClick={() => setShowTooltip(true)}
      >
        {children}
      </div>
    </div>
  )
}

export default Tooltip
