import React, { useState } from 'react'

const NewModal = ({ children }): JSX.Element => {
  const [isClosing, setIsClosing] = useState(false)
  return (
    <div className="fixed bg-black bg-opacity-80 top-0 bottom-0 left-0 z-50 w-full sm:h-screen">
      <div
        className={`flex flex-col slide-up w-full sm:my-24 sm:mx-auto sm:max-w-screen-lg relative
        bg-light-40 sm:bg-white sm:rounded-t-2xl sm:rounded-b-2xl h-screen sm:h-max sm:max-h-90 sm:overflow-hidden overflow-auto ${
          isClosing ? 'slide-down' : ''
        }`}
      >
        {children}
      </div>
    </div>
  )
}
export default NewModal
