import Image from 'next/image'
import React from 'react'
import { BsBuilding } from '@react-icons/all-files/bs/BsBuilding'

import { titleCase } from 'src/lib/utilities'
import ShortRating from '../generic/ShortRating'
import Pill from '../generic/Pill'
import { Building, Tag } from '@openigloo/types'
import { useRouter } from 'next/router'

interface OpeniglooBuildingCardProps {
  building: Building
  customCardStyle?: string
  showImage?: boolean
  showMaskIfUnreviewed?: boolean
  onCardClick?: () => void
}

const OpeniglooBuildingCard = ({
  building,
  customCardStyle = '',
  showImage = true,
  showMaskIfUnreviewed = true,
  onCardClick,
}: OpeniglooBuildingCardProps): JSX.Element => {
  const router = useRouter()

  const imageUrl =
    building && building.images && building.images.length > 0
      ? building.images[0]
      : '/building-placeholder-desktop.svg'
  return (
    <div
      className={`relative shadow-md rounded-lg overflow-hidden h-full ${
        building?.productBuilding?.reviewCount ? 'cursor-pointer' : ''
      } ${customCardStyle}`}
      onClick={() => {
        if (onCardClick) {
          onCardClick()
          return
        }
        if (building?.productBuilding?.reviewCount) {
          router.push(`/building/${building.productBuilding.id}`)
        }
      }}
    >
      {/* {!building.productBuilding.reviewCount && showMaskIfUnreviewed && (
        <div className="absolute top-0 right-0 bottom-0 left-0 z-50 bg-white bg-opacity-60">
          <div className="flex flex-col justify-center text-center">
            <div className="mt-16">No building reviews yet</div>
          </div>
        </div>
      )} */}
      {showImage && (
        <div className="relative flex w-full h-48">
          <Image src={imageUrl} alt="building" layout="fill" />
        </div>
      )}

      <div className="inline-flex px-2 mt-3 mb-1 w-full items-center">
        <div className="max-w-1/6 mr-2">
          <BsBuilding size={30} />
        </div>
        <address className="flex-grow text-sm font-semibold not-italic flex-wrap sm:text-xs">
          <div>{titleCase(building.fullAddress)}</div>
        </address>
        {building.productBuilding && building.productBuilding.averageRating && (
          <ShortRating
            value={building.productBuilding.averageRating || 0}
            count={building.productBuilding.reviewCount || 0}
            titleStyle="text-sm font-bold"
            countStyle="text-sm"
            containerStyle=" justify-self-end"
          />
        )}
      </div>

      <div className="flex flex-wrap mt-2 px-2 w-full mb-2">
        {building.tags &&
          building.tags.slice(0, 2).map((tag: Tag, index: number) => {
            return (
              <Pill
                label={tag.label}
                key={tag.key}
                color={tag.count > 0 ? 'yellow' : 'purple'}
                pillStyle="my-1 mx-0.5 truncate text-xs"
              />
            )
          })}
      </div>
    </div>
  )
}

export default OpeniglooBuildingCard
