import { snakeCase } from 'lodash'

const Section = ({
  heading,
  children,
  customStyle = '',
}: {
  heading?: string
  editable?: boolean
  children: React.ReactNode
  customStyle?: string
}) => {
  return (
    <div className={`p-4 ${customStyle}`} id={heading ? snakeCase(heading) : ''}>
      {heading ? <h4 className="font-bold mb-4">{heading}</h4> : <></>}
      {children}
    </div>
  )
}

export default Section
