import { InfoSectionLayout } from '@components/index'
import { currency, titleCase, getNames } from 'src/lib/utilities'
import { LeaseProfile, ManagedCityUnit } from 'src/@types/manager'
import { useRouter } from 'next/router'
import Table from '@components/table'
import { ColDef } from 'ag-grid-community'
import { useEffect, useState } from 'react'
import { useThunkDispatch } from '@redux/hooks'

const ManagedTenants = ({
  managedUnits,
  showBuildingAddress = false,
}: {
  managedUnits: ManagedCityUnit[]
  showBuildingAddress?: boolean
}) => {
  const dispatch = useThunkDispatch()
  const router = useRouter()

  const [rowData, setRowData] = useState([])

  useEffect(() => {
    if (managedUnits?.length > 0) {
      const flattenedLeasesByUnit = []
      managedUnits.forEach((unit) => {
        if (unit.leases?.length > 0) {
          flattenedLeasesByUnit.push(
            unit.leases?.reduce(
              (prevV, currV) => {
                return {
                  id: currV.id,
                  buildingId: unit.buildingId,
                  unitId: currV.unitId,
                  fullAddress: currV.fullAddress,
                  rent: prevV.rent + currV.rent,
                  profiles: [...prevV.profiles, ...currV.profiles],
                }
              },
              { rent: 0, profiles: [] }
            )
          )
        }
      })
      setRowData(flattenedLeasesByUnit)
    }
  }, [managedUnits])

  const [columnDefs] = useState<ColDef[]>([
    ...(showBuildingAddress
      ? [
          {
            field: 'fullAddress',
            headerName: 'Building',
            wrapText: true,
          },
        ]
      : []),
    {
      field: 'unitId',
      headerName: 'Unit #',
      valueFormatter: ({ value }) => {
        return getUnitNumber(value)
      },
    },
    {
      headerName: 'Tenants Name',
      field: 'profiles',
      wrapText: true,
      valueFormatter: ({ value }) => {
        return getNames(value)
      },
    },
    {
      headerName: 'Rent',
      field: 'rent',
      valueFormatter: ({ value }) => {
        return currency(value)
      },
    },
    {
      headerName: '',
      field: '',
      cellClass: 'cursor-pointer',
      sortable: false,
      autoHeight: true,
      cellRenderer: ({ data }) => {
        return (
          <button
            className="px-4 rounded-full my-2 bg-lightred-300 text-white"
            onClick={() => {
              router.push(`/buildings/${data.buildingId}/unit/${data.unitId}/tenant`)
            }}
          >
            View
          </button>
        )
      },
    },
  ])

  const getUnitNumber = (unitId: string) => {
    if (managedUnits && managedUnits.length > 0) {
      const unit = managedUnits.find((unit) => unit.cityUnit.productUnit.id === unitId)
      return unit?.cityUnit.unitNumber
    }
  }

  return (
    <div className="overflow-x-auto -mx-7">
      {rowData?.length > 0 ? (
        <Table rowData={rowData} columnDefs={columnDefs} />
      ) : (
        <div className="flex flex-col items-center mt-6 justify-center">
          <span className="my-2">No tenants yet.</span>
        </div>
      )}
    </div>
  )
}

export default ManagedTenants
