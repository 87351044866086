import React, { useEffect, useState } from 'react'
import OpenDataDescription from './OpenDataDescription'
import { Modal, Skeleton } from '..'
import { useAppSelector } from '@redux/hooks'
import FlagIcon from '@public/icons/flag-icon.svg'
import { BsArrowDown } from 'react-icons/bs'
import { isMobile as rddIsMobile } from 'react-device-detect'
import { ExpandBox } from '..'
import TrendUpIcon from '@public/icons/trend-up-icon.svg'
import TrendDownIcon from '@public/icons/trend-down-icon.svg'
export interface OpenDataModalProps {
  onClose: () => void
  collection: string
}

enum Collection {
  Violations = 'open_violations',
  Evictions = 'evictions',
  Litigations = 'litigations',
  BedbugViolations = 'bedbug_infestations_1y',
}

const OpenDataModal = ({ onClose, collection }: OpenDataModalProps): JSX.Element => {
  const { opendata } = useAppSelector((state) => state.currentBuilding)
  const [title, setTitle] = useState(null)
  const [pillsData, setPillsData] = useState(null)
  const [pillsDataSummary, setPillsDataSummary] = useState(null)
  const [currentViolation, setCurrentViolation] = useState('classA')
  const [currentBedbugs, setCurrentBedbugs] = useState('')
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(rddIsMobile)
  }, [rddIsMobile])

  const getLabel = () => {
    switch (collection) {
      case Collection.Violations:
        return 'Violations'
      case Collection.Evictions:
        return 'Evictions'
      case Collection.Litigations:
        return 'Litigation history'
      case Collection.BedbugViolations:
        return '1 year bedbug history'
      default:
        return ''
    }
  }

  useEffect(() => {
    // console.log(opendata)
    // console.log(collection)
    const curPillsData = opendata?.[collection]
    curPillsData && setPillsData(curPillsData.data)
    if (curPillsData) {
      setTitle(`${getLabel()}`)
      if (collection === Collection.Violations) {
        if (curPillsData.data['classA'].length === 0) {
          if (curPillsData.data['classB'].length === 0) setCurrentViolation('classC')
          else setCurrentViolation('classB')
        }
      } else if (collection === Collection.BedbugViolations) {
        setCurrentBedbugs(Object.keys(curPillsData.data)[0])
      }
      setPillsDataSummary(curPillsData.summary)
    }
  }, [collection])

  const getOpenViolationDescription = (group: string) => {
    switch (group) {
      case 'classA':
        return {
          bg: 'bg-yellow-75',
          type: 'non-hazardous',
          desc: '(i.e. no peephole on a door, or no street # on the building, unlawful keeping of animals)',
        }
      case 'classB':
        return {
          bg: 'bg-yellow-200',
          type: 'hazardous',
          desc: '(i.e. smoke detector issues, inadequate lighting, no lighting for stairways)',
        }
      case 'classC':
        return {
          bg: 'bg-red-200',
          type: 'immediately hazardous',
          desc: '(i.e. rodents, pest, mold, inadequate heat or hot water, defective building parts)',
        }
      default:
        return {}
    }
  }

  const renderItems = () => {
    if (!pillsData) return
    else {
      if (collection === Collection.Litigations) {
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Object.keys(pillsData).map((group) => {
              const pending = pillsData[group].filter((s) => s.status === 'pending')
              return (
                <div
                  className={`w-full bg-light-10 p-4 rounded-lg border ${
                    pending.length > 0 ? 'border-yellow-400' : 'border-light-40'
                  }`}
                  key={`litigations_${group}`}
                >
                  <span className="block">
                    <span className="text-2xl font-semibold">{pillsData[group].length}</span>{' '}
                    {pillsData[group][0].caseType}
                  </span>
                  <span
                    className={`block mt-2 text-sm ${
                      pending.length > 0 ? 'text-yellow-500' : 'text-mid-300'
                    }`}
                  >
                    Status: {pending.length > 0 ? `${pending.length} pending` : 'all closed'}
                  </span>
                </div>
              )
            })}
          </div>
        )
      } else if (collection === Collection.Evictions) {
        return (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {Object.keys(pillsData).map((group) => {
              return (
                <div
                  className={`w-full bg-light-10 rounded-lg border border-light-40`}
                  key={`evictions_${group}`}
                >
                  <div className="bg-yellow-500 rounded-t-lg px-4 py-2">
                    <span className="block text-light-0">
                      <span className="text-lg font-semibold">{group}</span> (
                      {pillsData[group].length} evictions)
                    </span>
                  </div>
                  <div className="pt-2 pb-4 px-6">
                    {pillsData[group].map((e, index) => (
                      <div className="mt-2" key={`eviction_child_${group}_${index}`}>
                        <span className="text-sm block font-semibold">
                          Court Index Number:{' '}
                          <span className="font-normal">{e.courtIndexNumber}</span>
                        </span>
                        <span className="text-sm block font-semibold">
                          Execution Date: <span className="font-normal">{e.executionDate}</span>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )
            })}
          </div>
        )
      } else if (collection === Collection.Violations) {
        return (
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              {['classA', 'classB', 'classC'].map((group) => {
                return (
                  <div
                    key={`violations_${group}`}
                    className={`w-full ${getOpenViolationDescription(group).bg} rounded-lg ${
                      pillsData[group]?.length > 0 ? 'cursor-pointer' : ''
                    }`}
                    onClick={() => pillsData[group]?.length > 0 && setCurrentViolation(group)}
                  >
                    <div className="flex flex-row items-start justify-between">
                      <span className="pt-10 pl-4">
                        <span className="text-lg font-semibold">
                          {pillsData[group]?.length ?? 0}
                        </span>{' '}
                        Class {group.slice(-1)}
                      </span>
                      <div className="flex flex-row items-center justify-end bg-yellow-100 p-2 w-fit rounded-tr-lg rounded-bl-lg">
                        <FlagIcon className="text-mid-400 mr-1" />{' '}
                        <span className="text-sm text-mid-400">
                          {getOpenViolationDescription(group).type}
                        </span>
                      </div>
                    </div>
                    <div className="mx-4 mb-6">
                      <span className="text-sm text-mid-400">
                        {getOpenViolationDescription(group).desc}
                      </span>
                    </div>
                    {isMobile ? (
                      pillsData[group]?.length > 0 ? (
                        <ExpandBox
                          title="Learn more"
                          key={group}
                          customTitleStyle="bg-blue-500 text-light-0 text-sm font-normal"
                          customChildrenStyle="border border-light-40 py-0"
                        >
                          {pillsData[group].map((row, index) => (
                            <div
                              className="py-4 border-b border-light-40"
                              key={`expand_${group}_${index}`}
                            >
                              <div className="flex flex-row items-center mb-2">
                                <span className="text-sm font-semibold mr-4">
                                  {row.approvalDate}
                                </span>
                                <span className="text-xxs text-dark-700">
                                  Status: {row.currentStatus}
                                </span>
                              </div>
                              <span className="text-xs block whitespace-normal">
                                {row.novDescription}
                              </span>
                            </div>
                          ))}
                        </ExpandBox>
                      ) : null
                    ) : (
                      <>
                        <div className="bg-blue-500 text-light-0 rounded-b-lg py-2 px-4 relative top-2 flex flex-row items-center">
                          <span className="mr-2">Learn more</span>
                          <BsArrowDown />
                        </div>
                        {currentViolation === group ? (
                          <div className="h-0 w-0 border-x-8 border-t-8 border-x-transparent border-t-blue-500 relative left-1/2 top-2" />
                        ) : null}
                      </>
                    )}
                  </div>
                )
              })}
            </div>
            {!isMobile && pillsData[currentViolation]?.length > 0 ? (
              <div className="mt-4">
                <div className="grid grid-cols-[200px_auto_200px] py-4 bg-yellow-200 rounded-t-xl">
                  <span className="text-dark-700 text-center">Approval Date</span>
                  <span className="text-dark-700 text-center">Nov Description</span>
                  <span className="text-dark-700 text-center">Current Status</span>
                </div>
                {pillsData[currentViolation].map((row, index) => (
                  <div
                    className="grid grid-cols-[200px_auto_200px] gap-4 py-4 border-b border-light-40 rounded-t-xl bg-light-10"
                    key={`${currentViolation}_table_${index}`}
                  >
                    <span className="text-dark-700 text-xs pl-4">{row.approvalDate}</span>
                    <span className="text-dark-700 text-xs">{row.novDescription}</span>
                    <span className="text-dark-700 text-xs pl-4">{row.currentStatus}</span>
                  </div>
                ))}
              </div>
            ) : null}
          </>
        )
      } else if (collection === Collection.BedbugViolations) {
        return currentBedbugs && pillsData[currentBedbugs]?.length > 0 ? (
          <>
            <div key={`bedbug_${currentBedbugs}`} className={`w-full bg-yellow-200 rounded-lg`}>
              <div className="flex flex-row items-start justify-between">
                <span className="pt-10 pl-4">
                  <span className="text-lg font-semibold">{currentBedbugs}</span> (
                  {pillsData[currentBedbugs].length} case)
                </span>
                <div className="flex flex-row items-center justify-end bg-yellow-100 p-2 w-fit rounded-tr-lg rounded-bl-lg">
                  <FlagIcon className="text-mid-400 mr-1" />{' '}
                  <span className="text-sm text-mid-400">hazardous</span>
                </div>
              </div>
              <div className="mx-4 mb-6">
                <span className="text-sm text-mid-400">
                  (NYC lists bedbugs as a class B violation. This means they are considered
                  hazardous.)
                </span>
              </div>
              <div className="bg-blue-500 text-light-0 rounded-b-lg py-2 px-4 relative top-2 flex flex-row items-center">
                <span className="mr-2">Learn more</span>
                <BsArrowDown />
              </div>

              <div className="h-0 w-0 border-x-8 border-t-8 border-x-transparent border-t-blue-500 relative left-1/2 top-2" />
            </div>

            <div className="mt-4">
              <div className="grid grid-cols-[200px_auto_200px] py-4 bg-yellow-200 rounded-t-xl">
                <span className="text-dark-700 text-center">Approval Date</span>
                <span className="text-dark-700 text-center">Nov Description</span>
                <span className="text-dark-700 text-center">Current Status</span>
              </div>
              {pillsData[currentBedbugs].map((row, index) => (
                <div
                  className="grid grid-cols-[200px_auto_200px] gap-4 py-4 border-b border-light-40 rounded-t-xl bg-light-10"
                  key={`bedbug_${currentBedbugs}_${index}`}
                >
                  <span className="text-dark-700 text-xs pl-4">{row.approvalDate}</span>
                  <span className="text-dark-700 text-xs">{row.novDescription}</span>
                  <span className="text-dark-700 text-xs pl-4">{row.currentStatus}</span>
                </div>
              ))}
            </div>
          </>
        ) : null
      }
      return null
    }
  }

  return (
    <Modal title={title} onClose={onClose} large>
      {!pillsData ? (
        <div className="p-2 space-y-3">
          <Skeleton style="w-full" />
          <Skeleton style="w-full" />
          <Skeleton style="w-full" />
        </div>
      ) : (
        <div className="p-4 overflow-y-scroll mb-24">
          {pillsDataSummary && (
            <div>
              <span className="text-dark-700">
                This building has{' '}
                <span className="text-dark-900 font-semibold">
                  {pillsDataSummary.perUnitBuilding} violations per unit
                </span>
                for their entire portfolio.
              </span>

              <div className="flex flex-row items-center mt-2 text-sm">
                {pillsDataSummary.perUnitBuilding > pillsDataSummary.perUnitCity ? (
                  <TrendDownIcon />
                ) : (
                  <TrendUpIcon />
                )}
                <span className="ml-2 text-dark-700">
                  That is{' '}
                  <span className="text-dark-900 font-semibold">
                    {pillsDataSummary.perUnitBuilding > pillsDataSummary.perUnitCity
                      ? 'worse'
                      : 'better'}{' '}
                    than the city average,
                  </span>{' '}
                  which is {pillsDataSummary.perUnitCity} violations per unit.
                </span>
              </div>
            </div>
          )}
          <div className="h-max">
            <OpenDataDescription collection={collection} />
          </div>
          <div className="w-full flex-1 py-4">{renderItems()}</div>
        </div>
      )}
    </Modal>
  )
}

export default OpenDataModal
