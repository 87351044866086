import React, { useState, useEffect } from 'react'
import Image from 'next/image'

import { FaEdit } from '@react-icons/all-files/fa/FaEdit'
import { FaTrash } from '@react-icons/all-files/fa/FaTrash'
import { GiCancel } from '@react-icons/all-files/gi/GiCancel'

import StarRating from '../generic/StarRating'
import { OwnerComment } from '@openigloo/types'
import dynamic from 'next/dynamic'
import ClampLines from 'react-clamp-lines'
import BeatLoader from 'react-spinners/BeatLoader'
import { BiCheck } from 'react-icons/bi'
import { segment } from 'src/lib/analytics'
import { FiThumbsDown, FiThumbsUp } from 'react-icons/fi'
import { ImEmbed2 } from 'react-icons/im'
import { ButtonOutline, Separator } from '..'
import EmbedReviewModal from '@components/building/EmbedReviewModal'
import { Review } from 'src/@types/manager'

type ReviewCardProps = {
  review: Review
  onCommentEdit: (r: Review, nc: string) => void
  onCommentDelete: (c: OwnerComment) => void
  canComment?: boolean
  handleShowMore?: (val: boolean, review: Review) => void
  showExpanded?: boolean
  showImageSection?: boolean
  showBuildingAddress?: boolean
  showInteraction?: boolean
}

const ReviewCard = ({
  review,
  canComment = true,
  onCommentEdit,
  onCommentDelete,
  handleShowMore,
  showExpanded = false,
  showImageSection = false,
  showBuildingAddress = false,
  showInteraction = true,
}: ReviewCardProps): JSX.Element => {
  const [comment, setComment] = useState<string>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [submitted, setSubmitted] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (submitted) {
      setTimeout(() => setSubmitted(false), 3000)
    }
  }, [submitted])

  useEffect(() => {
    if (review) {
      setComment(review.ownerComment?.comment || '')
    }
  }, [review, review.ownerComment])

  return (
    <div className="border border-light-40 bg-white rounded-lg">
      <div className="flex flex-row w-full pt-4 px-6 justify-between">
        <div className="mr-8">
          <StarRating rating={review.rating} starSize={20} />
          <div className="font-semibold text-dark-900 pt-2">{review.title}</div>
          {showBuildingAddress && review.building && (
            <div className="text-sm text-mid-500 pt-2">{review.building.address}</div>
          )}
        </div>
        <div className="text-sm text-dark-700">
          <div>{review.createdTime}</div>
          <div className="pt-4">{review.isCurrentTenant ? 'Current Tenant' : 'Former Tenant'}</div>
        </div>
      </div>
      <div className="inline-flex w-full flex-wrap justify-start px-6 pt-2 space-y-1">
        {review.approveOwner && (
          <div className="flex items-center pr-4 text-green-400">
            <FiThumbsUp />
            <div className="pl-1 text-sm">Approves owner</div>
          </div>
        )}
        {review.approveOwner === false && (
          <div className="flex items-center pr-4 text-red-400">
            <FiThumbsDown />
            <div className="pl-1 text-sm">Does not approve owner</div>
          </div>
        )}
        {review.recommendBuilding && (
          <div className="flex items-center pr-4 text-green-400">
            <FiThumbsUp />
            <div className="pl-1 text-sm">Recommends Building</div>
          </div>
        )}
        {review.recommendBuilding === false && (
          <div className="flex items-center pr-4 text-red-400">
            <FiThumbsDown />
            <div className="pl-1 text-sm">Does not recommend building</div>
          </div>
        )}
      </div>
      <Separator customStyle="mx-6" />
      <div className="pt-4 w-full px-6 space-y-4 text-dark-900">
        <div className="w-full">
          <p className="font-semibold">Pros:</p>
          {showExpanded ? (
            <div className="pt-2">{review.pros}</div>
          ) : (
            <>
              <ClampLines
                text={review.pros}
                id={review.id + '-pros'}
                lines={review.advice ? 2 : 6}
                buttons={false}
                ellipsis="..."
                moreText="See more"
                lessText="Collapse"
                className="text-sm"
              />
            </>
          )}
        </div>
        <div className="w-full pb-4">
          <p className="font-semibold">Cons:</p>
          {showExpanded ? (
            <div className="pt-2">{review.cons}</div>
          ) : (
            <>
              <ClampLines
                text={review.cons}
                id={review.id + '-cons'}
                lines={review.advice ? 2 : 4}
                buttons={false}
                ellipsis="..."
                moreText="See more"
                lessText="Collapse"
                className="text-sm"
              />
            </>
          )}
        </div>
      </div>
      {review.advice && (
        <div className="pb-4 px-6">
          <p className="font-semibold">Advice to owner:</p>
          {showExpanded ? (
            <div className="pt-2">{review.advice}</div>
          ) : (
            <>
              <ClampLines
                text={review.advice}
                id={review.id + '-advice'}
                lines={2}
                buttons={false}
                ellipsis="..."
                moreText="See more"
                lessText="Collapse"
                className="text-sm"
              />
            </>
          )}
        </div>
      )}
      {showImageSection && review?.photos?.length > 0 && (
        <>
          <div className="flex flex-row flex-wrap w-full mb-2">
            {review.photos.map((photo, index) => {
              return (
                <div key={`review-photos-${index}`} className="w-24 h-24 rounded-md relative mx-1">
                  <Image
                    alt="Review Image"
                    src={photo}
                    layout="fill"
                    objectFit="cover"
                    className="rounded-md"
                  />
                </div>
              )
            })}
          </div>
        </>
      )}
      {showInteraction && (
        <>
          <Separator customStyle="mx-6" />
          <div className="flex justify-end px-6 py-1">
            <ButtonOutline
              label={'Embed review'}
              className="w-full sm:w-fit"
              withIcon
              Icon={<ImEmbed2 className="mr-2 text-black " size={20} />}
              onClick={() => setShowModal(true)}
            />
          </div>
          <Separator customStyle="mb-0" />
          <div className="bg-light-10 rounded-b-lg py-4">
            {canComment ? (
              <>
                <div className="px-6">
                  <div className="text-dark-900 font-semibold">Response from owner:</div>
                  <textarea
                    className="w-full rounded-lg border border-light-40 flex-1 mt-3"
                    placeholder="Your response here..."
                    value={comment}
                    onChange={(e) => {
                      setComment(e.target.value)
                    }}
                  />
                  {review.ownerComment ? (
                    <div className="text-xs text-mid-400 mt-2">
                      {'Comment ' +
                        (review.ownerComment.status == 'pending'
                          ? 'pending approval'
                          : review.ownerComment.status)}
                    </div>
                  ) : null}
                </div>

                {comment && (
                  <div
                    className={`flex items-center !my-2 md:my-0 mx-6 
                ${
                  !submitting && !submitted && review.ownerComment
                    ? 'justify-between'
                    : 'justify-end'
                }`}
                  >
                    {!submitting && !submitted && review.ownerComment && (
                      <button
                        className="text-lightred-300 underline text-sm mr-6"
                        onClick={async () => {
                          if (!review.ownerComment) {
                            return
                          }
                          setSubmitting(true)
                          await onCommentDelete(review.ownerComment)
                          setComment('')
                          setSubmitting(false)
                        }}
                      >
                        Delete
                      </button>
                    )}
                    <button
                      className={`text-white sm:w-fit w-full py-2 px-4 text-center rounded-full ${
                        submitted
                          ? 'bg-green-500'
                          : !(submitting || comment == review.ownerComment?.comment)
                          ? 'bg-lightred-300'
                          : 'bg-lightred-100 hover:cursor-auto'
                      }`}
                      onClick={async () => {
                        if (!comment) {
                          return
                        }
                        segment.trackRespondToReview(review.id, comment)
                        setSubmitting(true)
                        await onCommentEdit(review, comment)
                        setSubmitting(false)
                        setSubmitted(true)
                        // TODO: Update usermanagedBuilding and currentbuilding with comment
                      }}
                      disabled={submitting || comment == review.ownerComment?.comment}
                    >
                      {submitted ? (
                        <span className="inline-flex items-center justify-center text-xs px-1">
                          <span>Submitted</span>
                          <span>
                            <BiCheck />
                          </span>
                        </span>
                      ) : submitting ? (
                        <span className="w-full px-3">
                          <BeatLoader size={5} color="white" />
                        </span>
                      ) : (
                        <span className="w-full px-3">Post my response</span>
                      )}
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="flex-1">
                <div>{review?.ownerComment?.comment}</div>
              </div>
            )}
          </div>
        </>
      )}
      {showModal && <EmbedReviewModal onClose={() => setShowModal(false)} review={review} />}
    </div>
  )
}

export default ReviewCard
