export const Skeleton = ({ style = '' }) => {
  return (
    <i className={`text-transparent bg-gray-200 rounded-md inline-block animate-pulse ${style}`}>
      .
    </i>
  )
}

export const ListCardSkeleton = ({ style = '' }) => {
  return (
    <li className={`flex rounded-xl shadow-md p-4 px-4 mb-1 ${style}`}>
      <div className="w-3/6">
        <p className="mb-1">
          <Skeleton style="w-full" />
        </p>
        <p className="w-3/4">
          <Skeleton style="w-full" />
        </p>
      </div>
      <div className="ml-auto">
        <Skeleton style="w-12" />
      </div>
    </li>
  )
}

export const BuildingCardSkeleton = () => {
  return (
    <div className="mr-8 mb-8 shadow-md pb-2 rounded-lg w-full">
      <Skeleton style="w-full h-24 rounded-b-none" />
      <ListCardSkeleton style="rounded-none shadow-none" />
      <div className="flex justify-start space-x-3 px-4">
        <Skeleton style="w-1/3" />
        <Skeleton style="w-1/3" />
      </div>
    </div>
  )
}
