type Props = {
  type: 'info' | 'warning' | 'error'
  customStyle?: string
  children: React.ReactElement
}
export default function AlertBanner({ type, customStyle = null, children }: Props) {
  const bgStyle = {
    info: 'bg-primarylight',
    warning: 'bg-warning',
    error: 'bg-red-300',
  }
  const textStyle = {
    info: 'text-white',
    warning: 'text-black',
    error: 'text-white',
  }
  return (
    <div
      className={`${
        customStyle ||
        'z-30 flex fixed w-full top-0 left-0 h-10 items-center text-sm font-semibold justify-center'
      } ${bgStyle[type]} ${textStyle[type]}`}
    >
      {children}
    </div>
  )
}
