import useFirebaseStorage from 'src/hooks/useFirebaseStorage'
import { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import ImageIcon from '@public/icons/image-icon.svg'

type UploadImageProps = {
  storageUrl: string
  onUploadComplete: (urls: string[]) => void
  customStyle?: string
  isMultipleFilesAllowed?: boolean
}

const UploadImages = ({
  storageUrl,
  onUploadComplete,
  customStyle = '',
  isMultipleFilesAllowed = true,
}: UploadImageProps) => {
  const { setFilesToUpload, filesUploadStatus, isUploadingFinished, uploadedUrls } =
    useFirebaseStorage({
      storageUrl: storageUrl,
    })
  const [isDragging, setIsDragging] = useState(false)

  useEffect(() => {
    if (isUploadingFinished && uploadedUrls.length > 0) {
      onUploadComplete(uploadedUrls)
      setFilesToUpload([])
    }
  }, [isUploadingFinished])

  const uploadDocument = (files: File[]) => {
    setFilesToUpload(files)
    setIsDragging(false)
  }

  return (
    <>
      <div className="border rounded-2xl my-4 w-full">
        <Dropzone
          onDrop={(acceptedFiles) => uploadDocument(acceptedFiles)}
          onDragEnter={() => setIsDragging(true)}
          onDragLeave={() => setIsDragging(false)}
          multiple={isMultipleFilesAllowed}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div
                  className={`flex flex-col relative justify-center items-center h-48 ${customStyle}`}
                >
                  <div
                    className={`w-full h-full flex flex-col items-center justify-center px-4 text-center hover:cursor-pointer absolute rounded-xl border z-0 bg-light-10 border-lightred-200 ${
                      isDragging ? 'filter blur-md' : ''
                    }`}
                  >
                    <ImageIcon className="mb-2 text-secondary" />
                    <span className="text-secondary font-semibold">
                      Drag and drop files anywhere or click here to upload
                    </span>
                    <span className="text-secondary font-semibold my-2">or</span>
                    <div className="bg-secondary px-4 py-2 rounded-3xl">
                      <span className="text-light-0">Browse for files</span>
                    </div>
                  </div>
                  {isDragging && (
                    <div className="w-full absolute h-full flex items-center justify-center rounded-xl z-10 border-dashed border-lightred-300 border-2">
                      <span className="text-secondary font-semibold">Drop here</span>
                    </div>
                  )}
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
      <ul className="w-full text-center my-2">
        {filesUploadStatus.length > 0 ? (
          <span className="text-secondary font-semibold mb-2 text-center">
            Uploading {filesUploadStatus.length} files...
          </span>
        ) : null}
        {filesUploadStatus.length > 0 &&
          filesUploadStatus.map((status, index) => {
            return (
              <li
                key={`upload-${index}`}
                className={`flex justify-between items-center p-1 max-w-md mx-auto
                  }`}
              >
                <span>Image {`${index + 1}`}</span>
                <label className="overflow-hidden relative flex-grow-2 border w-6/12 h-2 rounded-full">
                  <span
                    className="bg-secondary h-full absolute top-0 left-0 transition-width"
                    style={{ width: `${status.progress}%` }}
                  ></span>
                </label>
              </li>
            )
          })}
      </ul>
    </>
  )
}

export default UploadImages
