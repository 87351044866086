import InfoSectionLayout from '@components/common/InfoSectionLayout'
import { useAuth } from '@stores/authContext'
import { useRouter } from 'next/router'
import React, { useState, useEffect } from 'react'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import { Auth } from 'src/data'
const ManageApplicationsOptIn = ({ onComplete, toggle = false }) => {
  const [optIn, setOptIn] = useState(false)

  const { userPermissions } = useAuth()
  const router = useRouter()
  useEffect(() => {
    if (userPermissions && userPermissions.role) {
      setOptIn(userPermissions.isManagingApplications)
    }
  }, [userPermissions])

  const addManagingApplications = async () => {
    if (userPermissions.role == 'agent' || userPermissions.role == 'broker') {
      await Auth.addApplicationsMgmt()
      onComplete()
    } else {
      alert('You do not have permission to perform this action.')
    }
  }

  const handleToggleChange = (e) => {
    setOptIn(e.target.checked)
    if (e.target.checked) {
      addManagingApplications()
    }
  }

  return toggle ? (
    <InfoSectionLayout editable>
      <>
        <div className="flex flex-wrap justify-between w-full items-center sm:items-start">
          <div className="w-3/5 font-semibold">
            Opt in to collect verified applications for your posted listings?
          </div>
          <div className="w-2/5 px-4">
            <Toggle
              disabled={userPermissions.isManagingApplications}
              checked={optIn}
              onChange={(e) => handleToggleChange(e)}
              icons={false}
              name="optIn"
            />
          </div>
        </div>
        <div className="text-sm w-3/5">
          On openigloo renters are filling out their applications ahead of time. By opting in,
          renters can submit their full application to you which you can review and approve on this
          openigloo manager dashboard.
        </div>
      </>
    </InfoSectionLayout>
  ) : (
    <div className="rounded-lg p-4 border-2 max-w-screen-md shadow-md mx-auto space-y-8">
      <div className="text-lg font-semibold text-center">
        Would you like to collect verified applications for your posted listings?
      </div>
      <div className="text-sm text-center">
        On openigloo renters are filling out their applications ahead of time. By opting in, renters
        can submit their full application to you which you can review and approve on this openigloo
        manager dashboard.
      </div>
      <div className="w-full flex justify-evenly items-center">
        <button
          className="primary-button rounded-full bg-lightred-300 text-white w-1/4"
          onClick={() => addManagingApplications()}
        >
          Yes
        </button>
        {/* <button className="primary-button rounded-full bg-purplegray-100 w-1/4">No</button> */}
      </div>
    </div>
  )
}

export default ManageApplicationsOptIn
