import React from 'react'

type PillProps = {
  label: string
  color?: 'yellow' | 'purple' | 'red' | 'published' | 'rejected' | 'pending' | null
  pillStyle?: string
  labelStyle?: string
}

const Pill = ({
  label = 'Open Violations: 17',
  color = null,
  pillStyle = '',
  labelStyle = '',
}: PillProps): JSX.Element => {
  const styles = {
    yellow: 'bg-yellow-50 border-yellow-100 text-blue-200',
    purple: 'bg-blue-25 border-bluegray-75 text-blue-200',
    red: 'bg-lightred-100  text-lightred-300',
    pending: 'bg-yellow-500 border-yellow-50 text-light-0',
    published: 'bg-green-50 border-green-75 text-green-500',
    rejected: 'bg-red-500 border-red-50 text-white',
  }
  return (
    <div
      className={`${pillStyle} text-center m-1 rounded-2xl border ${color ? styles[color] : ''}`}
    >
      <span className={`text-xs px-2 ${labelStyle}`}>{label}</span>
    </div>
  )
}

export default Pill
