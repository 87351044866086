import React, { useState, useRef } from 'react'
import { IoIosArrowBack } from '@react-icons/all-files/io/IoIosArrowBack'
import { IoIosArrowForward } from '@react-icons/all-files/io/IoIosArrowForward'

import Carousel from 'react-multi-carousel'
import { ArrowProps, DotProps } from 'react-multi-carousel/lib/types'
import 'react-multi-carousel/lib/styles.css'

interface HorizontalScrollSectionProps {
  customContainerStyle?: string
  customItemStyle?: string
  children: React.ReactNode
  customResponsive?: { [key: string]: any } | null
  showDots?: boolean
  showArrows?: boolean
}

const CustomRightArrow = ({ onClick, ...rest }: ArrowProps) => {
  return (
    <button
      onClick={() => onClick && onClick()}
      className="absolute right-4 bg-white rounded-full p-2 shadow-md focus:outline-none"
      title="forward arrow"
    >
      <IoIosArrowForward />
    </button>
  )
}
const CustomLeftArrow = ({ onClick, ...rest }: ArrowProps) => {
  return (
    <button
      onClick={() => onClick && onClick()}
      className="absolute left-4 bg-white rounded-full p-2 shadow-md focus:outline-none"
      title="back arrow"
    >
      <IoIosArrowBack />
    </button>
  )
}

const CustomDot = (props: DotProps) => {
  return (
    <button
      className={`rounded-full w-2.5 h-2.5 border mx-0.5 my-2 'border-white' ${
        props.active ? 'bg-white' : ''
      }`}
      {...props}
    />
  )
}

const HorizontalScrollSection = ({
  children,
  customContainerStyle,
  customItemStyle,
  customResponsive = null,
  showDots = false,
  showArrows = true,
}: HorizontalScrollSectionProps): JSX.Element => {
  const responsive =
    customResponsive !== null
      ? customResponsive
      : {
          superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
            slidesToSlide: 3,
          },
          desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3,
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2,
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1,
          },
        }

  return (
    <Carousel
      showDots={showDots}
      arrows={showArrows}
      responsive={responsive}
      draggable={true}
      swipeable={true}
      partialVisible={true}
      customRightArrow={<CustomRightArrow />}
      customLeftArrow={<CustomLeftArrow />}
      containerClass={`carousel-container ${customContainerStyle}`}
      dotListClass="custom-dot-list-style"
      itemClass={`carousel-item-padding-40-px ${customItemStyle}`}
      customDot={<CustomDot />}
      shouldResetAutoplay={false}
    >
      {children}
    </Carousel>
  )
}

export default HorizontalScrollSection
