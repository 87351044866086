import { Timestamp } from 'firebase/firestore/lite'
import { usaStates } from 'typed-usa-states/dist/states'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import { LeaseProfile } from 'src/@types/manager'
const stateArray = usaStates.map((state) => state.abbreviation.toLowerCase())
const companySuffixArray = ['llc', 'id', 'lp']

type currencyOptions = {
  significantDigits: number
  thousandsSeparator: string
  decimalSeparator: string
  symbol: string
}

export const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const ShortDay = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(utc)

export const splitAddress = (address: string): { addressLine1: string; addressLine2: string } => {
  const line1 = address.split(', ')[0]
  const line2 = address.split(', ').slice(1).join(', ')
  return { addressLine1: line1, addressLine2: line2 }
}

export const sentenceCase = (text: string) => {
  const start = text.charAt(0).toUpperCase()
  return start + text.toLowerCase().substring(1)
}

export const titleCase = (text = '', splitChar = ' '): string => {
  const stringArr = text.toLowerCase().split(splitChar)
  for (let i = 0; i < stringArr.length; i++) {
    const str = stringArr[i]
    if (stateArray.indexOf(str) > -1 || companySuffixArray.indexOf(str) > -1) {
      stringArr[i] = str.toUpperCase()
    } else {
      stringArr[i] = str.charAt(0).toUpperCase() + str.slice(1)
    }
  }
  return stringArr.join(' ')
}

export const validateEmail = (email: string): boolean => {
  const re = /\S+@\S+\.\S+/
  return re.test(email)
}

export const validateUrl = (url: string) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(url)
}

export const fromNow = (date: Date): string => {
  return dayjs().to(dayjs(date), false)
}

export const daysAgo = (date: Date): string => {
  return dayjs().to(dayjs(date), true)
}

export const formatDate = (date: Date | string, format: string) => {
  return dayjs(date).format(format)
}

export const getNinetyDaysFromNow = (date): any => {
  return dayjs().add(90, 'days').toDate()
}

export const formatTenancyDates = (date: Date): string => {
  return dayjs(date).format('YYYY-MM-DD')
}
export const currency: (value: number | string, options?: currencyOptions) => string = function (
  value,
  options = {
    significantDigits: 2,
    thousandsSeparator: ',',
    decimalSeparator: '.',
    symbol: '$',
  }
): string {
  if (typeof value === 'string') {
    if (value === '') {
      return ''
    }
    try {
      value = parseFloat(value)
    } catch (error) {
      value = 0
    }
  }
  const stringVal = value.toFixed(options.significantDigits)
  const [currency, decimal] = stringVal.split('.')
  const decimalText = options.significantDigits > 0 ? `${options.decimalSeparator}${decimal}` : ''
  return `${options.symbol} ${currency.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    options.thousandsSeparator
  )}${decimalText}`
}

export const formatPublishedDate = (date: Date): string => {
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()

  return `${day}-${Months[month].slice(0, 3)}-${year}`
}

export const formatShortDate = (date: Date): string => {
  const year = date.getFullYear()
  const month = date.getMonth()
  const day = date.getDate()

  return `${month + 1}/${day}/${year.toString().slice(2)}`
}

export const roundToNearestHour = (date: Date) => {
  date.setMinutes(date.getMinutes() + 30)
  date.setMinutes(0, 0, 0)

  return date
}

export const formatPaymentDate = (date: Date): string => {
  const year = date.getFullYear().toString().slice(2)
  const month = date.getMonth()
  const day = date.getDate()

  return `${Months[month].slice(0, 3)}-${day}-${year}`
}

export const formatFullDate = (date: Date, monthOnly: boolean = false): string => {
  const year = date.getFullYear().toString()
  const month = date.getMonth()
  const day = date.getDate()

  if (monthOnly) {
    return `${Months[month]}, ${year}`
  }

  return `${Months[month]} ${day}, ${year}`
}

export const formatUpdatedTimestamp = (date: Date): string => {
  const datePart = formatShortDate(date)
  const hour = date.getHours()
  const mins = date.getMinutes()
  return datePart.toString() + ' ' + hour.toString() + ':' + mins.toString()
}

export const formatRent = (number: number): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumSignificantDigits: 5,
  })
  return formatter.format(number)
}

export const formatPhoneNumber = (phoneNumberString: string): string => {
  const val = phoneNumberString.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
  return !val[2] ? val[1] : '(' + val[1] + ') ' + val[2] + (val[3] ? '-' + val[3] : '')
}

export const formatOpenHouseTimes = (dateObject: { date: Date; hours: Date[] }): string[] => {
  const year = dateObject.date.getFullYear()
  const month =
    dateObject.date.getMonth() > 8
      ? dateObject.date.getMonth() + 1
      : '0' + (dateObject.date.getMonth() + 1)
  const day = dateObject.date.getDate()

  const dateString = [year, month, day].join('-')
  const startTime = dateObject.hours[0].getHours() + ':' + '00' + ':' + '00'
  const endTime = dateObject.hours[1].getHours() + ':' + '00' + ':' + '00'
  const tZMins = new Date().getTimezoneOffset()
  const tZHours = Math.floor(tZMins / -60)
  const tZindicator = tZHours > 0 ? '+' + tZHours + ':00' : tZHours + ':00'
  return [dateString + 'T' + startTime + tZindicator, dateString + 'T' + endTime + tZindicator]
}

export const dateToTimestamp = (date: Date): Timestamp | undefined => {
  if (!date) return
  return Timestamp.fromDate(date)
}

export const bedroomLabel = (no_bedrooms: number | string): string => {
  const bedroomNumber = parseInt(no_bedrooms.toString())
  if (bedroomNumber === 0) {
    return 'Studio'
  } else if (bedroomNumber === 1) {
    return '1 Bedroom'
  } else {
    return `${bedroomNumber} Bedrooms`
  }
}

export const mapApplicationStatusToOwnerView = {
  draft: 'Draft',
  submitted: 'Awaiting Review',
  in_review: 'Awaiting Review',
  withdrawn: 'Withdrawn',
  accepted: 'Offer accepted by landlord',
  rejected: 'Offer rejected by landlord',
  declined: 'Offer declined by applicant',
  confirmed: 'Offer confirmed by applicant',
}

export const listingStatusFormat = {
  open: 'text-green-400 bg-green-50',
  closed: 'text-gray-400 bg-gray-100',
  draft: 'text-yellow-400 bg-yellow-50',
  pending: 'text-red-400 bg-red-50',
}

export const applicationStatusFormat = {
  submitted: 'text-yellow-200',
  in_review: 'text-yellow-400',
  withdrawn: 'text-gray-50',
  accepted: 'text-green-400 border border-dashed border-green-400',
  rejected: 'text-red-400 bg-red-50',
  declined: 'text-red-400 bg-red-50 border-red-400 border',
  confirmed: 'font-bold text-green-400 border bg-green-50 border-green-400',
}

export const numberRegEx = /^[0-9]*$/

export const handleNumberValidation = (e: any, setValue: (v: any) => void) => {
  if (e.target.value.match(numberRegEx)) {
    setValue(e.target.value)
  } else {
    alert('Please enter a number')
  }
}

export const unitStatusValue = {
  true: 'Occupied',
  false: 'Vacant',
}

export const unitStatusFormat = {
  true: 'bg-green-50 text-green-400',
  false: 'bg-bluegray-25 text-bluegray-300',
}

const unitTierLimit = {
  1: 100,
  2: 500,
  3: 1000000000,
}

const pricingTier = (totalUnits: number) => {
  if (totalUnits < 100) {
    return 1
  } else if (totalUnits < 500) {
    return 2
  } else {
    return 3
  }
}

export const hasMinWords = (content: string, minWords: number) => {
  return content && content.trim().split(' ').length >= minWords
}

export const moveArrayItems = (arr: any[], oldIndex: number, newIndex: number) => {
  if (newIndex >= arr.length) {
    var k = newIndex - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
  return arr
}

export const dateStringToObj = (dateString): Date => {
  if (dateString) {
    const stringArr = dateString.split('-')
    return new Date(stringArr[0], parseInt(stringArr[1]) - 1, stringArr[2].includes('T') ? stringArr[2].split('T')[0] : stringArr[2])
  } else return null
}

export const pluralizeString = (string: string, count: number, suffix = 's') =>
  `${count} ${string}${count !== 1 ? suffix : ''}`

export const groupBy = (arrayToBeGrouped, key) => {
  return arrayToBeGrouped.reduce(function (prev, curr) {
    ;(prev[curr[key]] = prev[curr[key]] || []).push(curr)
    return prev
  }, {})
}

export const formatCount = (number: number): string => {
  return new Intl.NumberFormat().format(number)
}

export const getNames = (profiles: LeaseProfile[]) => {
  const names = profiles.reduce(
    (prevV, currV, index) =>
      ` ${prevV} ${titleCase(currV.name)}${index + 1 === profiles.length ? '' : ','}`,
    ''
  )
  return names
}

export const monthYearFromString = (month: string | Date): string => {
  let dateObj
  if (typeof month == 'string') {
    dateObj = dateStringToObj(month)
  } else {
    dateObj = month
  }
  const monthStr = Months[dateObj.getMonth()]
  const yearStr = dateObj.getFullYear()
  return monthStr + ' ' + yearStr
}

export const getFirstDayOfMonth = (date: Date, monthPrevious?: number) => {
  return new Date(date.getFullYear(), date.getMonth() - (monthPrevious || 0), 1)
}

export function monthDiff(dateFrom, dateTo) {
  return (
    dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  )
}
