import React from 'react'

export const TextFormLabel = ({ children, className = '', isRequired = false }) => {
  return (
    <span className={`w-full font-semibold text-base text-dark-900 ${className}`}>
      {children}
      {isRequired ? <span className="text-red-400 ml-1">*</span> : null}
    </span>
  )
}
