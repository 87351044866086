import Image from 'next/image'
import { AiOutlineClose, AiOutlineDrag } from 'react-icons/ai'
import { IoExpandSharp, IoTrashSharp } from 'react-icons/io5'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { moveArrayItems } from 'src/lib/utilities'
import NewModal from '@components/generic/NewModal'
import { useState } from 'react'
interface ShowUploadedImagesProps {
  images: string[]
  onEdit: (images: string[]) => void
  isEditable?: boolean
  ImageChild?: (props: any) => JSX.Element
  onChildClick?: (image: string) => void
}

const ShowUploadedImages = ({
  images,
  onEdit,
  isEditable = false,
  ImageChild = null,
  onChildClick = null,
}: ShowUploadedImagesProps) => {
  const [showImageFullScreen, setShowImageFullScreen] = useState(null)

  const DragHandle = SortableHandle(() => (
    <span
      tabIndex={0}
      className="absolute z-10 p-0.5 top-4 -right-2 cursor-move bg-secondary text-white rounded-full"
    >
      <AiOutlineDrag color="#fff" size={16} />
    </span>
  ))

  const SortableItem = SortableElement(({ position, value }) => (
    <li className={`mr-4 mb-4 w-28 relative`}>
      {isEditable && images.length > 1 && <DragHandle />}
      <Image
        src={value}
        width={100}
        height={100}
        layout="responsive"
        alt="forupload"
        className="rounded-lg"
      />
      {isEditable && (
        <button
          onClick={() => {
            let photos = [...images]
            const filteredPhotos = photos.filter((image) => image != value)
            onEdit(filteredPhotos)
          }}
          title="Remove"
          className={`absolute -right-2.5 top-24 z-10 bg-red-400 rounded-full p-1`}
        >
          <IoTrashSharp color="#fff" size={14} />
        </button>
      )}
      <button
        onClick={() => {
          setShowImageFullScreen(value)
        }}
        title="Expand"
        className={`absolute -right-2 -top-2 z-10 bg-secondary rounded-full p-0.5`}
      >
        <IoExpandSharp color="#fff" size={16} />
      </button>
      {ImageChild && (
        <ImageChild
          image={value}
          onClick={() => isEditable && onChildClick && onChildClick(value)}
        />
      )}
    </li>
  ))

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul className="flex items-start flex-wrap my-1">
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            disabled={!isEditable}
            index={index}
            position={index}
            value={value}
          />
        ))}
      </ul>
    )
  })

  const onSortEnd = ({ oldIndex, newIndex }, e) => {
    if (!isEditable || oldIndex === newIndex) {
      return
    }
    const array = [...images]
    const newArray = moveArrayItems(array, oldIndex, newIndex)
    onEdit(newArray)
  }

  return (
    <>
      {images.length ? <div className="w-full text-secondary">{images.length} photo(s)</div> : null}
      {isEditable && images.length > 1 ? (
        <span className="w-full text-mid-400 text-sm mb-2">Move images to arrange order</span>
      ) : null}
      <div className="flex items-start flex-wrap my-1">
        <SortableList
          helperClass={'list-none'}
          items={images}
          useDragHandle={true}
          onSortEnd={onSortEnd}
          axis="xy"
          lockToContainerEdges={true}
        />
      </div>
      {showImageFullScreen ? (
        <NewModal>
          <div className="flex flex-col text-sm w-full p-4 overflow-auto">
            <div className="flex w-full justify-between mb-2">
              <div className="font-semibold text-lg">
                <span className="mr-1">Image preview</span>
              </div>
              <div
                className="text-lightred-300 hover:cursor-pointer"
                onClick={() => setShowImageFullScreen(null)}
              >
                <AiOutlineClose color={'#E23668'} size={20} />
              </div>
              <div className="absolute right-4 top-4"></div>
            </div>
            <div className="relative w-full" style={{ height: 'calc(100vh - 200px)' }}>
              <Image
                src={showImageFullScreen}
                width={100}
                height={100}
                layout="fill"
                objectFit="contain"
                alt="forupload"
              />
            </div>
          </div>
        </NewModal>
      ) : null}
    </>
  )
}

export default ShowUploadedImages
