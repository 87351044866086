import React, { useState } from 'react'
import { SectionSubheader } from '@components/index'
import { AiOutlineClose } from 'react-icons/ai'

const FilterModal = ({ title, onClose, large = false, children }): JSX.Element => {
  const [isClosing, setIsClosing] = useState(false)
  return (
    <div className="fixed bg-black bg-opacity-80 top-0 bottom-0 left-0 z-50 w-full h-screen">
      <div
        className={`flex flex-col slide-up w-full my-24 sm:mx-auto ${
          large ? 'sm:max-w-screen-lg' : 'sm:max-w-screen-md'
        } bg-white sm:rounded-2xl sm:rounded-t-2xl h-screen sm:max-h-90 sm:overflow-hidden ${
          isClosing ? 'slide-down' : ''
        }`}
      >
        <div className="w-full h-12 inline-flex justify-between border-b p-4">
          <SectionSubheader style="mr-4">{title}</SectionSubheader>
          <div
            onClick={() => {
              setIsClosing(true)
              setTimeout(() => onClose(), 400)
            }}
            className="hover:cursor-pointer"
          >
            <AiOutlineClose color={'#E23668'} size={20} />
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}
export default FilterModal
