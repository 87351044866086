import PositiveNumberInput from '@components/generic/PositiveNumberInput'
import React, { useEffect, useState } from 'react'
import { Application } from 'src/@types/manager'
import { currency, titleCase } from 'src/lib/utilities'

interface ApplicantRentSplitProps {
  application: Application
  setIsRentSplitValid: any
  totalSplitObj: any
  setTotalSplitObj: any
  rent: number
}

const ApplicantRentSplit = ({
  application,
  setIsRentSplitValid,
  totalSplitObj,
  setTotalSplitObj,
  rent,
}: ApplicantRentSplitProps) => {
  const [totalSplit, setTotalSplit] = useState(0)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    if (totalSplitObj) {
      setTotalSplit(
        //   @ts-ignore
        Object.values(totalSplitObj).reduce((partialSum, a) => partialSum + parseFloat(a), 0)
      )
    }
  }, [totalSplitObj])

  useEffect(() => {
    if (totalSplit > 100) {
      setShowError(true)
    } else {
      setShowError(false)
    }
  }, [totalSplit])

  useEffect(() => {
    if (totalSplit == 100) {
      setIsRentSplitValid(true)
    } else {
      setIsRentSplitValid(false)
    }
  }, [totalSplit])

  return (
    <div className="w-full">
      {application.applicants &&
        application.applicants.map((applicant) => {
          const displayNumber = (parseFloat(totalSplitObj[applicant.email]) / 100) * rent
          return (
            <div className="w-full inline-flex items-center" key={applicant.name}>
              <div className="flex-1 pl-2">{titleCase(applicant.name)}</div>
              <div className="inline-flex items-center mr-5 space-x-3 w-1/2 text-center">
                <div>
                  <PositiveNumberInput
                    numberType="float"
                    min={0}
                    max={100}
                    placeholder="Enter contribution"
                    className="w-full"
                    value={totalSplitObj[applicant.email]}
                    onChange={(e) => {
                      setTotalSplitObj({
                        ...totalSplitObj,
                        [applicant.email]: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className="w-1/2">
                  {currency(displayNumber ? displayNumber.toFixed(2) : 0)}
                </div>
              </div>
            </div>
          )
        })}
      <div className="inline-flex w-full font-bold">
        <div className="flex-1">Total amount</div>
        <div className="inline-flex items-center mr-5 space-x-3 w-1/3 text-center">
          <div className="w-1/2">{totalSplit ? totalSplit : 0}%</div>
          <div className="w-1/2">${totalSplit ? ((totalSplit / 100) * rent).toFixed(2) : 0}</div>
        </div>
      </div>
      {showError && <div className="text-red-400">Total contribution must equal 100</div>}
    </div>
  )
}

export default ApplicantRentSplit
